<template>
    <div class="proBanner">
        <swiper ref="mySwiper" class="swiper" :options="options">
            <swiper-slide class="swiperct" v-for="(item, index) in $t('homeIndex.banners')" :key="index" :data-path="item.path">
                <img :src="splitImageHttp(item.src)" alt="" :data-path="item.path">
            </swiper-slide>
        </swiper>
    </div>
</template>
<script>
let vm = null
export default {
    name: 'proBanner',
    data() {
        return {
            choselange: 'zh',
            //配置项例子
            options: {
                slidesPerView: "auto",    //'auto'则自动根据slides的宽度来设定数量
                loop: true,
                autoplay: true, //设置为true启动自动切换，并使用默认的切换设置。
                autoplay: {
                    //swiper手动滑动之后自动轮播失效的解决方法,包括触碰，拖动，点击pagination,重新启动自动播放
                    disableOnInteraction: false,
                    // 自动播放时间：毫秒
                    delay: 5000,
                },
                freeMode: false,
                observer:true,//修改swiper自己或子元素时，自动初始化swiper
                observeParents:true,//修改swiper的父元素时，自动初始化swiper
                on: {
                    tap: function (e) {
                        if (!e.target.dataset.path) return
                        vm.productDetail(e.target.dataset.path)
                    },
                }
            },

        }
    },
    created() {
        vm = this
        this.choselange = localStorage.getItem('languageSet')
    },
    mounted(){
        //根据自己需要来监听对应的key
        window.addEventListener("setItemEvent", this.lange)
    },
    watch: {
        choselange(){
            this.$refs.mySwiper.swiper.loopDestroy()
            this.$refs.mySwiper.swiper.slideTo(0)
            this.$nextTick(() => 
            this.$refs.mySwiper.swiper.loopCreate(),  
            )
        }
    },
    methods: {
        lange(e){
           if(e.key==="languageSet"){
           this.choselange = e.newValue
           // newValue
           }
        },
        productDetail(path) {
            this.$router.push({ path: path })
        },
    },
    destroyed(){
        window.removeEventListener('setItemEvent', this.lange)
    }
}
</script>
<style lang="less" scoped>
.proBanner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-width: 1080px;
    min-height: 490px;
    margin-left: 16%;
}

.swiper {
    width: 100%;
    min-width: 1080px;
    //min-height: 490px;
    margin-top: 6.5%;

    .swiperct {
        cursor: pointer;
        width: 65%;
        margin: 0 6%;

        img {
            width: 100%;
        }
    }
}
</style>