<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
document.addEventListener('mousewheel', function (e) {
      e = e || window.event;
      if ((e.wheelDelta && event.ctrlKey) || e.detail) {
        event.preventDefault();
      }
    }, {
      capture: false,
      passive: false
    })
export default {
  name: 'App',
  created(){
  }
}
</script>

<style lang="less">
*{
    font-family: 'Thin';
}
#app {
  width: 100%;
  height: 100vh;
//   min-height: 100vh;
//   overflow: hidden;
}
img{
    display: block;
}
body,
h1, h2, h3, h4, h5, h6, hr, p, blockquote, dl, dt, dd, ul, ol, li, pre, form, fieldset, legend, button, input, textarea, th, td {
    margin: 0;
    padding: 0;
}
 
/* body, button, input, select, textarea {
    font: 12px/1.5 tahoma, arial, \5b8b\4f53;
} */
 
h1, h2, h3, h4, h5, h6 {
    font-size: 100%;
}
 
address, cite, dfn, em, var {
    font-style: normal;
}
 
code, kbd, pre, samp {
    font-family: couriernew, courier, monospace;
}
 
small {
    font-size: 12px;
}
 
ul, ol {
    list-style: none;
}
 
a {
    text-decoration: none;
}
 
a:hover {
    text-decoration: underline;
}
 
sup {
    vertical-align: text-top;
}
 
sub {
    vertical-align: text-bottom;
}
 
legend {
    color: #000;
}
 
fieldset, img {
    border: 0;
}
 
button, input, select, textarea {
    font-size: 100%;
}
 
table {
    border-collapse: collapse;
    border-spacing: 0;
}
 
</style>
