<template>
    <div class="talk">
        <swiper ref="mySwiper" class="swiper" :options="options">
            <!-- swiper-no-swiping -->
            <swiper-slide class="swiperct" v-for="(item, index) in $t('homeIndex.talkList')" :key="index">
                <img class="img" :src="splitImageHttp(item.srcImg)" alt="">
            </swiper-slide>
            <div class="btn" :style="{ height: talksHeight + 'px' }" :class="{ btnEn : choselange === 'en'}">
                <div class="lr btn-left"></div>
                <div class="lr btn-right"></div>
            </div>
        </swiper>
    </div>
</template>
<script>
export default {
    name: 'talk',
    props: {
        value: {
            typeof: Number,
            default: 0
        }
    },
    watch: {
        value() {
            this.talksHeight = this.value * (44 / 420)
        }
    },
    data() {
        return {
            choselange: 'zh',
            talksHeight: 0,
            options: {
                slidesPerView: "auto",
                autoplay: false, //设置为true启动自动切换，并使用默认的切换设置。
                // autoplay: {
                //     //swiper手动滑动之后自动轮播失效的解决方法,包括触碰，拖动，点击pagination,重新启动自动播放
                //     disableOnInteraction: false,
                //     // 自动播放时间：毫秒
                //     delay: 4000,
                // },
                loop: false,
                freeMode: false,
                effect: 'fade',
                fadeEffect: {
                    crossFade: true,
                },
                navigation: {
                    //下一张
                    nextEl: '.btn-right',//下一张标签类名可以自定义
                    //上一张
                    prevEl: '.btn-left'//上一张标签类名可以自定义
                },
                on: {
                    // click: function(e){
                    //   if(!e.target.dataset.path) return
                    //   vm.productDetail(e.target.dataset.path, e.target.dataset.series, e.target.dataset.product)
                    // }
                }
            },
        }
    }, 
    methods:{
        lange(e){
           if(e.key==="languageSet"){
           this.choselange = e.newValue
           // newValue
           }
        },
    }, 
    mounted() {
        //根据自己需要来监听对应的key
        window.addEventListener("setItemEvent", this.lange)
    },
    created(){ 
        this.choselange = localStorage.getItem('languageSet')
    },
    destroyed(){
        window.removeEventListener('setItemEvent', this.lange)
    },
}
</script>
<style lang="less" scoped>
.talk {
    position: relative;
    width: 100%;
    min-width: 960px;
    min-height: 420px;

    .btnEn{
        top: 18% !important;
        right: -1% !important
    }

    .btn {
        width: 13%;
        min-width: 108px;
        min-height: 55px;
        display: flex;
        position: absolute;
        top: 18%;
        right: 24%;

        .lr {
            display: block;
            //background-color: #000;
            width: 41%;
            height: 100%;
            border-radius: 50%;
            min-width: 44px;
            min-height: 44px;
            z-index: 9999;
            cursor: pointer;
            outline: none;
        }

        .btn-left {
            margin-right: 18%;
        }
    }
}

.swiper {
    width: 67%;
    min-width: 960px;
    min-height: 420px;
    margin: 0 auto;
    //overflow: hidden;

    .swiperct {
        width: 100%;
        min-width: 960px;
        min-height: 420px;


        .img {
            width: 100%;
            //margin: 0 2px;
        }
    }
}
</style>