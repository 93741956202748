<template>
<div style="width:100%">
    <div class="kongbai"></div>
    <div class="tops">
        <div class="top">
            <img :src="splitImageHttp('metapure/metapure_web/PC/home/logo_top.png')" alt="" @click="choseNav('/home')">
            <div class="top_nav" :style="{width: lang ? '770px' :'752px'}">
                <span :style="{minHeight: '20px',fontWeight: lang ? '' : '400',marginLeft: lang ? '40px' : ''}" :class="{ pcSpan:pcSpan, chose: index === indexkey,spanEn:!lang}" v-for="(item, indexkey) in $t('home.menu')" :key="indexkey" 
                    @click="choseNav(item.path)">{{ item.name
                    }}</span>
                <img style="width:30px;height:30px;margin-left:40px; float: left;" :src="lang ? en : ch" alt="" @click="chose()">
            </div>
        </div>
    </div>
</div>
    
</template>
<script>
import en from '@/assets/en.png'
import ch from '@/assets/ch.png'

export default {
    name: 'NavTop',
    props: {
        index: {
            typeof: Number,
            default: ()=>{}
        }
    },
    data() {
        return {
            pcSpan:true,
            offsetTop: 0,
            en: en,
            ch: ch,
            lang: true,
        }
    },
    methods: {
        choseNav(path, index) {
            if (this.$route.path === path && this.$route.query.index === index) return this.$router.go(0)
            this.$router.push({ path: path })
        },
        chose() {
           this.lang = !this.lang
           this.$i18n.locale=='zh'?this.$i18n.locale='en':this.$i18n.locale='zh'   //设置中英文模式
           localStorage.setItem('languageSet',this.$i18n.locale)
        }
    },
    created(){
        this.lang = localStorage.getItem('languageSet')=='zh'||localStorage.getItem('languageSet')==null?true:false
        if(navigator.maxTouchPoints>0){
            this.pcSpan =false
        }else{
            this.pcSpan =true
        }
    },
    // mounted(){
    //     if(this.lang) return localStorage.setItem('languageSet','zh')
    //     return localStorage.setItem('languageSet','zh')
        
    // }
}

</script>
<style lang="less" scoped>

.tops {
    width: 100%;
    height: 120px;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.93);
    z-index: 999;
    backdrop-filter: blur(35px);
    // backdrop-filter: blur(35px);
}
.kongbai {
        width: 1080px;
        max-width: 1440px;
        height: 120px;
        margin: 0 auto;
    }

.top {
    width: 1080px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 60px;
    margin: 0 auto;


    img {
        width: 100px;
        height: 100px;
        cursor: pointer;
    }

}

.top_nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    //min-height: 20px;
    margin-left: 90px;

}

.chose {
    color: #3EB1C8 !important;
}

.top_nav span {
    font-size: 14px;
    font-weight: 600;
    color: #212529;
    line-height: 20px;
    cursor: pointer;
    text-align: center;
    margin-left: 30px;
}

.pcSpan:hover {
    color: #3EB1C8;
}

.top_nav .spanEn:nth-child(1){
    width: 60px;
}
.top_nav .spanEn:nth-child(2){
    width: 90px;
}
.top_nav .spanEn:nth-child(3){
    width: 60px;
}
.top_nav .spanEn:nth-child(4){
    width: 88px;
}
.top_nav .spanEn:nth-child(5){
    width: 90px;
}
.top_nav .spanEn:nth-child(6){
    width: 45px;
}
.top_nav .spanEn:nth-child(7){
    width: 39px;
}
</style>