<template>
    <div class="bt">
        <div class="btc">
            <!-- 图标 -->
            <div class="logos" :style="{marginRight: choselange ==='en' ? '40px' : '258px'}">
                <img class="logo" :src="splitImageHttp('metapure/metapure_web/PC/home/logo_bt.png')" alt=""
                    @click="transform('/home')">
                <div class="icons">
                    <img class="wx" :src="splitImageHttp(wxsrc)" alt="" @mouseenter="handleMouseEnter(1)"
                        @mouseleave="handleMouseLeave(1)">
                    <img class="st" :src="splitImageHttp(stsrc)" alt="" @mouseenter="handleMouseEnter(2)"
                        @mouseleave="handleMouseLeave(2)">
                    <!-- 暂无跳转链接 -->
                    <img class="tb" :src="splitImageHttp(tbsrc)" alt="" @mouseenter="handleMouseEnter(3)"
                        @mouseleave="handleMouseLeave(3)" @click="goShop('https://meitapuer.tmall.com')">
                    <img class="jd" :src="splitImageHttp(jdsrc)" alt="" @mouseenter="handleMouseEnter(4)"
                        @mouseleave="handleMouseLeave(4)" @click="goShop('https://mall.jd.com/index-12973301.html')">
                    <img class="dy" :src="splitImageHttp(dysrc)" alt="" @mouseenter="handleMouseEnter(5)"
                        @mouseleave="handleMouseLeave(5)">
                    <div class="qrCode wxCode" v-show="wxShow">
                        <img :src="splitImageHttp($t('btMenu.wxCode'))" alt="">
                    </div>
                    <div class="qrCode stCode" v-show="stShow">
                        <img :src="splitImageHttp($t('btMenu.stCode'))" alt="">
                    </div>
                    <div class="qrCode dyCode" v-show="dyShow">
                        <img :src="splitImageHttp($t('btMenu.dyCode'))" alt="">
                    </div>
                </div>
            </div>
            <!-- 产品 -->
            <div class="btc_product" :style="{width: choselange==='en' ? '95px' : '56px'}">
                <span class="product_title" :style="{fontWeight: choselange ==='en' ? '400' : ''}">{{ $t('btMenu.title1') }}</span>
                <span class="product_ms" :class="{ btSpan:btSpan }" v-for="(item, index) in $t('btMenu.proCenter') " @click="transform(item.path)" :key="index" :style="{fontWeight: choselange ==='en' ? '300' : ''}">{{
                    item.name }}</span>
            </div>
            <div class="btc_product" :style="{width: choselange==='en' ? '90px' : '56px',marginLeft: choselange==='en' ? '20px' : ''}">
                <span class="product_title" :style="{fontWeight: choselange ==='en' ? '400' : ''}">{{ $t('btMenu.title2') }}</span>
                <span class="product_ms" :class="{ btSpan:btSpan }" v-for="(item, index) in $t('btMenu.Service')" @click="goAbout(item.path, item.key)"
                    :key="index" :style="{fontWeight: choselange ==='en' ? '300' : ''}">{{ item.name }}</span>
            </div>
            <div class="btc_product" :style="{width: choselange==='en' ? '170px' : '70px',marginLeft: choselange==='en' ? '20px' : ''}">
                <span class="product_title" :style="{fontWeight: choselange ==='en' ? '400' : ''}">{{ $t('btMenu.title3') }}</span>
                <span class="product_ms" :class="{ btSpan:btSpan }" v-for="(item, index) in $t('btMenu.Research')" @click="goAbout(item.path, item.key)"
                    :key="index" :style="{fontWeight: choselange ==='en' ? '300' : ''}">{{ item.name }}</span>
            </div>
            <div class="btc_product" :style="{width: choselange==='en' ? '102px' : '56px',marginLeft: choselange==='en' ? '20px' : ''}">
                <span class="product_title" :style="{fontWeight: choselange ==='en' ? '400' : ''}">{{ $t('btMenu.title4') }}</span>
                <span class="product_ms" :class="{ btSpan:btSpan }" v-for="(item, index) in $t('btMenu.About')" @click="goAbout(item.path, item.key)"
                    :key="index" :style="{fontWeight: choselange ==='en' ? '300' : ''}">{{ item.name }}</span>
            </div>
            <!-- 版权 -->
            <div class="btc_btabout">
                <div class="hr"></div>
                <!-- href="https://beian.miit.gov.cn/#/Integrated/index" -->
                <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">{{ $t('btMenu.btabout') }}</a>
            </div>
        </div>
        <img v-show="topShow" class="gotop" :src="splitImageHttp('metapure/static/images/goTop.png')" alt=""
            @click="gotop()">
    </div>
</template>
<script>
export default {
    name: 'NavBt',
    data() {
        return {
            choselange: 'zh',
            wxsrc: 'metapure/metapure_web/PC/home/iconWx1_1.png',
            tbsrc: 'metapure/metapure_web/PC/home/iconTb1_1.png',
            jdsrc: 'metapure/metapure_web/PC/home/iconJd1_1.png',
            stsrc: 'metapure/metapure_web/PC/home/iconSt1_1.png',
            dysrc: 'metapure/metapure_web/PC/home/iconDy1_1.png',
            btSpan: true,
            wxShow: false,
            stShow: false,
            dyShow: false,
            topShow: false,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll)
       const _this = this
        //根据自己需要来监听对应的key
        window.addEventListener("setItemEvent",_this.lange)
    },
    methods: {
        lange(e){
            if(e.key==="languageSet"){
            this.choselange = e.newValue
            // newValue
            }
        },
        gotop() {
            window.scrollTo({ top: 0, behavior: 'smooth' })
        },
        handleScroll() {
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            if (scrollTop > 0) {
                this.topShow = true
                return
            }
            this.topShow = false
        },
        transform(path, index) {
            if (this.$route.path === path) return this.$router.go(0)
            this.$router.push({ path: path, query: { index: index } })
        },
        goAbout(path, index) {
            if (this.$route.query.index === index && this.$route.path === path) return this.$router.go(0)
            this.$router.push({ path: path, query: { index: index } })
            //this.$router.go(0)
        },
        handleMouseEnter(key) {
            if (key === 1) {
                this.wxShow = true
                this.wxsrc = 'metapure/metapure_web/PC/home/iconWx1_2.png'
                return
            }
            if (key === 2) {
                this.stShow = true
                this.stsrc = 'metapure/metapure_web/PC/home/iconSt1_2.png'
                return
            }
            if (key === 3) {
                this.tbsrc = 'metapure/metapure_web/PC/home/iconTb1_2.png'
                return
            }
            if (key === 4) {
                this.jdsrc = 'metapure/metapure_web/PC/home/iconJd1_2.png'
                return
            }
            if (key === 5) {
                this.dyShow = true
                this.dysrc = 'metapure/metapure_web/PC/home/iconDy1_2.png'
            }
        },
        handleMouseLeave(key) {
            if (key === 1) {
                this.wxShow = false
                this.wxsrc = 'metapure/metapure_web/PC/home/iconWx1_1.png'
                return
            }
            if (key === 2) {
                this.stShow = false
                this.stsrc = 'metapure/metapure_web/PC/home/iconSt1_1.png'
                return
            }
            if (key === 3) {
                this.tbsrc = 'metapure/metapure_web/PC/home/iconTb1_1.png'
                return
            }
            if (key === 4) {
                this.jdsrc = 'metapure/metapure_web/PC/home/iconJd1_1.png'
                return
            }
            if (key === 5) {
                this.dyShow = false
                this.dysrc = 'metapure/metapure_web/PC/home/iconDy1_1.png'
            }
        },
        goShop(url) {
            window.open(url)
        }
    },
    created(){
        if(navigator.maxTouchPoints>0){
            this.btSpan =false
        }else{
            this.btSpan =true
        }
        this.choselange = localStorage.getItem('languageSet')
    },
    destroyed(){
        window.removeEventListener('setItemEvent', this.lange)
    },
}
</script>
<style lang="less" scoped>

.gotop {
    position: fixed;
    right: 32px;
    width: 67px;
    height: 67px;
    bottom: 300px;
    cursor: pointer;
    z-index: 99999;
}

.bt {
    width: 100%;
    background-color: #fff;
}

.btc {
    width: 1080px;
    height: 369px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 60px 60px 0;
    display: flex;
    justify-content: space-between;
    position: relative;
    margin: 0 auto;

    /*图标*/
    .logos {
        width: 206px;
        height: 140px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .logo {
            width: 124px;
            height: 123px;
            cursor: pointer;
        }

        .icons {
            position: relative;
            display: flex;

            img {
                width: 30px;
                height: 30px;
                cursor: pointer;
                margin: 0 7px;
            }

            .qrCode {
                position: absolute;
                z-index: 999;
                width: 212px;
                height: 285px;

                img {
                    width: 212px;
                    height: 285px;
                }
            }

            .wxCode {
                top: -290px;
                left: -91px;
            }

            .stCode {
                top: -290px;
                left: -46px;
            }

            .dyCode {
                top: -290px;
                left: 85px;
            }
        }
    }

    /*产品*/
    .btc_product {
        display: flex;
        flex-direction: column;
        .product_title {
            font-size: 14px;
            font-weight: 600;
            color: #212529;
            line-height: 20px;
            //text-align: center;
            white-space: nowrap;
        }

        .product_ms {
            white-space: nowrap;
            font-size: 14px;
            font-weight: 400;
            color: #6C757D;
            line-height: 20px;
            margin-top: 20px;
            cursor: pointer;
            //text-align: center;
        }

        .btSpan:hover{
            color: #3EB1C8;
        }
    }

    /*版权*/
    .btc_btabout {
        position: absolute;
        width: 960px;
        height: 68px;
        bottom: 0;

        .hr {
            width: 960px;
            height: 1px;
            background: #E9ECEF;
        }

        a {
            // opacity: 0;
            display: block;
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            color: #6C757D;
            line-height: 20px;
            margin: 24px 0;

            &:hover{
                color: #3EB1C8  ;
            }
        }
    }

}
</style>