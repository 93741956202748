<template>
    <div class="home">
        <!-- 头部 -->
        <NavTop :index="0"></NavTop>
        <!-- 广告 -->
        <div class="banner_pro" :style="{ height: bannerHeight + 'px' }">
            <swiper ref="mySwiper" class="swiper" :options="options">
                <swiper-slide class=" banners1" v-for="(item, index) in banners" :key="index">
                    <img :src="splitImageHttp(item.src)" alt="" :data-path="item.path" />
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
        </div>
        <!-- 产品 -->
        <div class="products fade-in" :style="{ height: productsHeight + 'px' }">
            <div class="product">
                <proBanner></proBanner>
            </div>
        </div>
        <!-- 评价 -->
        <div class="talks fade-in" :style="{ height: talksHeight + 'px' }">
            <talk :value="talksHeight"></talk>
        </div>
        <!-- 品牌介绍 -->
        <div class="brand fade-in" :class="{ brandEn :choselange ==='en' }" :style="{ height: brandHeight + 'px' }" @click="transforms('/about', 3)">
        </div>
        <!-- 水 -->
        <div class="water fade-in" :class="{ waterEn :choselange ==='en' }" :style="{ height: bannerHeight + 'px' }" @click="transform('/about')">
        </div>
        <!-- 底部 -->
        <Navbt></Navbt>
    </div>
</template>
<script>
import NavTop from '@/components/pc/NavTop.vue'
import Navbt from '@/components/pc/NavBt.vue'
import proBanner from '@/components/pc/proBanner.vue'
import talk from '@/components/pc/everyTalk.vue'

let vm = null
export default {
    name: 'Home',
    components: {
        NavTop,
        Navbt,
        proBanner,
        talk,
    },
    data() {
        return {
            choselange: 'zh',
            clientWidth: 0,
            bannerHeight: 0,
            brandHeight: 0,
            productsHeight: 0,
            talksHeight: 0,
            banners: [
                { name: '制冰机', en: 'Beyond Water', src: 'metapure/metapure_web/PC/home/banner1_1.png', path: '/product/iceMachine' },
                { name: '龙头净水器', en: 'Beyond Water', src: 'metapure/metapure_web/PC/home/banner1_2.png', path: '/product/clearWater' },
            ],
            //配置项例子
            options: {
                // slidesPerView: "auto",    //'auto'则自动根据slides的宽度来设定数量
                loop: true,
                autoplay: true, //设置为true启动自动切换，并使用默认的切换设置。
                autoplay: {
                    //swiper手动滑动之后自动轮播失效的解决方法,包括触碰，拖动，点击pagination,重新启动自动播放
                    disableOnInteraction: false,
                    // 自动播放时间：毫秒
                    delay: 5000,
                },
                freeMode: false,
                on: {
                    tap: function (e) {
                        //if(!(navigator.maxTouchPoints>0)) return
                        if (!e.target.dataset.path) return
                        vm.productDetail(e.target.dataset.path)   
                    }
                },
                pagination: {
                    el: '.swiper-pagination',
                    //type: 'bullets',
                    //clickable: true,
                },
            },
        }
    },
    created() {
        vm = this
        this.windowWidth(document.documentElement.clientWidth)
        this.choselange = localStorage.getItem('languageSet')
    },
    methods: {
        langeHome(e){
            if(e.key==="languageSet"){
                this.choselange = e.newValue
                // newValue
            }
        },
        productDetail(path) {
            this.$router.push({ path: path })
        },
        transform(path) {
            if (this.$route.path === path) return
            this.$router.push({ path: path })
        },
        transforms(path, index) {
            if (this.$route.path === path) return this.$router.go(0)
            this.$router.push({ path: path, query: { index: index } })
        },
        //获取窗口宽度
        windowWidth(value) {
            this.clientWidth = value
        },
        fadeOut(evt) {
            //console.log(evt)
            for (var i = 0; i < this.fadeInElements.length; i++) {
                var elem = this.fadeInElements[i]
                if (this.isElemVisible(elem)) {
                    elem.style.opacity = 1
                    elem.style.transform = "translateY(0)"
                    elem.style.transition = "transform 1s ease-in-out 100ms, opacity 600ms"
                    //elem.classList.add('fade-out')
                    //this.fadeInElements.splice(i, 1) // 只让它运行一次
                } 
                else {
                    elem.style.opacity = 0
                    elem.style.transform = "translateY(80px)"
                    elem.style.transition = ""
                }


            }
        },
        // 判断元素距离窗口的位置
        isElemVisible(el) {
            var rect = el.getBoundingClientRect()
            var elemTop = rect.top + 50        // 200 = buffer
            //var elemBottom = rect.bottom
            return elemTop < window.innerHeight //&& elemBottom >= 0
        },
    },
    watch: {
        clientWidth(val) {
            if (!this.timer) {
                this.clientWidth = val
                this.timer = true
                let _this = this
                setTimeout(function () {
                    _this.timer = false
                }, 500)
            }
            // 这里可以添加修改时的方法
            this.windowWidth(val);
            if(val>=1080){
            this.bannerHeight = val / (1440 / 620)
            this.productsHeight = val / (1440 / 680)
            this.brandHeight = val / (1440 / 790)  
            this.talksHeight = val / (1440 / 420)
            }else{
            this.bannerHeight = 1080 / (1440 / 620)
            this.productsHeight = 1080 / (1440 / 680)
            this.brandHeight = 1080 / (1440 / 790)  
            this.talksHeight = 1080 / (1440 / 420)
            }
            
        }
    },
    mounted() {
        // 先获取全部需要过渡效果的元素
        this.fadeInElements = Array.from(document.getElementsByClassName('fade-in'))
        this.fadeOut()
        document.addEventListener('scroll', this.fadeOut)
        // 窗口变化执行    
        const _this = this;
        window.onresize = () => {
            return (() => {
                _this.clientWidth = `${document.documentElement.clientWidth}`
            })()
        }
        //根据自己需要来监听对应的key
        window.addEventListener("setItemEvent",this.langeHome)
    },
    destroyed(){
        window.removeEventListener('setItemEvent', this.langeHome)
    },

}
</script>
<style lang="less" scoped>
.swiper {
    /deep/ .swiper-pagination-bullet-active {
        background: rgba(255,255,255,1) !important;
    }

    .swiper-pagination {
        /deep/ .swiper-pagination-bullet {
            background:rgba(255,255,255,0.4);
            opacity: 1;
            width: 100px;
            height: 3px;
            border-radius: 2px;
            margin: 0 0 36px !important;
        }
    }
}

.home {
    width: 100%;
    min-width: 1080px;
    background-color: #FFFFFF;
}

/*广告轮播图*/
.banner_pro {
    width: 100%;
    min-width: 1080px;
    //min-height: 620px;
}

.banners1 {
    width: 100%;
    min-width: 1080px;
    //min-height: 620px;
    overflow: hidden;
    cursor: pointer;

    img {
        width: 100%;
        min-width: 1080px;
        //min-height: 620px;
    }
}

/*产品轮播图*/
.products {
    width: 100%;
    min-width: 1080px;
    //min-height: 680px;
    margin-top: 12%;
    background: #FFFFFF url('https://cdns.iamberry.com/metapure/metapure_web/PC/home/proBg1.png') no-repeat center;
    background-size: 100% 100%;

    .product {
        width: 100%;
        height: 100%;
        min-width: 1080px;
        //min-height: 680px;
        overflow: hidden;
    }
}

/*评价*/
.talks {
    width: 100%;
    background-color: #fff;
    min-width: 1080px;
    min-height: 420px;
    margin-top: 13%;
    margin-bottom: 13%;
}

/*品牌介绍*/
.brand {
    position: relative;
    width: 100%;
    min-width: 1080px;
    //min-height: 790px;
    background: url('https://cdns.iamberry.com/metapure/metapure_web/PC/home/brand1.png') no-repeat center;
    background-size: 100% 100%;
    margin-bottom: 12%;
    cursor: pointer;
}
.brandEn {
    background: url('https://cdns.iamberry.com/metapure/metapure_web/PC/home/EN/brandEn2.png')  no-repeat center !important;
    background-size: 100% 100% !important;
}


/*水*/
.water {
    width: 100%;
    min-width: 1080px;
    //min-height: 620px;
    background: #ffffff url('https://cdns.iamberry.com/metapure/metapure_web/PC/home/about1.png') no-repeat center;
    background-size: 100% 100%;
    cursor: pointer;
    margin-bottom: 8%;
}

.waterEn {
    background: #ffffff url('https://cdns.iamberry.com/metapure/metapure_web/PC/home/EN/aboutEn2.png') no-repeat center !important;
    background-size: 100% 100% !important;
}
</style>
