const home = {
    ZH: {
        //头部菜单
        menu: [
            { name: '美塔璞儿', path: '/home' }, { name: '净水龙头', path: '/product/clearWater' }, { name: '台式制冰机', path: '/product/iceMachine' }, { name: '水质地图', path: '/waterMap' }, { name: '研究与发展', path: '/research' }, { name: '服务中心', path: '/service' }, { name: '关于我们', path: '/about' }
        ],
        //底部菜单
        btMenu: {
            wxCode:'metapure/metapure_web/PC/home/wx_Code2.png',
            stCode:'metapure/metapure_web/PC/home/st_Code2.png',
            dyCode:'metapure/metapure_web/PC/home/dy_Code2.png',
            title1: '产品中心',
            title2: '服务中心',
            title3: '研究与发展',
            title4: '关于我们',
            btabout: '© 2016-2018 Metapure Corporation  深圳美塔璞儿科技有限公司 版权所有 ｜ 粤ICP备2023093252号-1',
            proCenter: [
                { name: '台式制冰机', path: '/product/iceMachine' },
                { name: '净水龙头', path: '/product/clearWater' },
            ],
            Service: [
                { name: '服务项目', path: '/service', key: '0' },
                { name: '联系我们', path: '/service', key: '1' },
                // { name: '服务活动', path: '/service', key: '1' },
            ],
            Research: [
                { name: '研究和创新', path: '/research', key: '0' },
                { name: '可持续发展', path: '/research', key: '1' },
                { name: '生产力与研发力', path: '/research', key: '2' },
            ],
            About: [
                { name: '关于美塔', path: '/about', key: '0' },
                { name: '合作共赢', path: '/about', key: '1' },
                { name: '质量方针', path: '/about', key: '2' },
                { name: '我们是谁', path: '/about', key: '3' },
            ],
        },
        //首页
        homeIndex:{
            banners: [
                { name: '制冰机', src: 'metapure/metapure_web/PC/home/proBanner1_1.png', path: '/product/iceMachine' },
                { name: '龙头净水器', src: 'metapure/metapure_web/PC/home/proBanner2x_2.png', path: '/product/clearWater' },
            ],
            talkList: [
                { id: '1', srcImg: 'metapure/metapure_web/PC/home/talkList/talks3_1.png' },
                { id: '2', srcImg: 'metapure/metapure_web/PC/home/talkList/talks2_1.png' },
                { id: '3', srcImg: 'metapure/metapure_web/PC/home/talkList/talks1_1.png' },
                // { id: '4', srcImg: 'metapure/metapure_web/PC/home/talkList/talks4.png' },
            ],
        },
        //服务中心首页
        service: {
            title: '服务中心',
            lianxi: '联系我们',
            kefu: '在线客服',
            Code: 'metapure/metapure_web/PC/service/wx_Code1.png',
            hujiao:'呼叫',
            fuzhi:'复制',
            Center: [
                { name: '产品保修和维修', src: 'metapure/metapure_web/PC/service/list1_1.png', note: '专业技术人员，服务有保障', path: '/service/productMaintenance', mPath:'/mobile/service/productStand' },
                { name: '安装指导和支持', src: 'metapure/metapure_web/PC/service/list1_2.png', note: '安装指南/视频教程', path: '/service/productInstall', mPath:'/mobile/service/productInstall' },
                { name: '滤芯更换和维护', src: 'metapure/metapure_web/PC/service/list1_3.png', note: '专业指导，维护保养您的设备', path: '/service/porudctReplacement', mPath:'/mobile/service/filterReplace' },
            ],
            ct: {
                ts1: '美塔璞儿官网',
                ts2: '服务中心',
                ts3: '产品保修和维护',
                ts4: '保修政策',
                ts5: '保修期限：',
                ts6: '保修范围：',
                ts7: '保修条件：',
                ts8: '保修服务方式：',
                ts9: '保修责任：',
                ts10: '售后支持：',
                ts11: '保修排除',
                ts12: '售后支持：',
                ts13: '维修流程',
                ts14: '安装指导和支持',
                ts15: '滤芯更换和维护',
                ts16: '视频教程',
                videomx: '若您根据安装指南和视频教程任然无法进行安装，您可以联系品牌400电话获取在线视频指导。'
            },
            proInstall: {
                title: '产品安装指南',
                titles: '使用转接头安装（带有起泡器的水龙头）',
                s1: '从水龙头上取下起泡器。',
                s2: '从净水器上取下连接螺母。',
                s3: '将转入密封圈的转接头穿过连接螺母，并固定在水龙头上。',
                s4: '将连接螺母拧紧到净水器上，安装完毕。',
                titles2: '使用万能接头安装（无起泡器的水龙头）',
                w1: '从净水器上取下连接螺母。',
                w2: '选择M22内螺纹转接头，将装入密封圈的转接头穿过连接螺母并与万能接头拧紧。',
                w3: '将万能接头装在水龙头上，并拧紧扎箍螺丝。',
                w4: '将连接螺母拧紧到净水器上，安装完毕。',
                waring: '注：水龙头外径15-19mm时直接把万能接头装上；水龙头外径19-24mm时，需取出万能接头内的胶圈再装上。',
                zbs1: '步骤一',
                zbs1_1: '在微信页面“搜索”框内输入“美塔璞儿”,点击进入“美塔璞儿”小程序授权登录页面。',
                zbs1_2: '通过微信“扫一扫”按钮，并对准扫码页面上的二维码进行扫描，即可自动跳转到授权登录页面。',
                zbs2: '步骤二',
                zbs2_1: '授权进入小程序内，点击左上角“',
                zbs2_2: '”图标，扫描机身二维码进行绑定。',
                zbs3: '步骤三',
                zbs3_1: '点击“确定绑定”及完成绑定操作。'
            },
            porudctReplacement: [
                {
                    product: '净水龙头',
                    id: '1',
                    replacementFrequency: {
                        head: '滤芯更换的频率',
                        content: [
                            '净水龙头：每6个月更换1次。',
                            '购买渠道：微信搜索“美塔璞儿”小程序进行选购滤芯。'
                        ]
                    },
                    replacementStep: {
                        head: '滤芯更换步骤',
                        content: [
                            'metapure/metapure_web/PC/service/replacement/Step2_1.png'
                        ],
                        //移动端
                        mContent:[
                            'metapure/metapure_web/Mobile/service/replace1.png'
                        ]
                    },
                    maintain: {
                        head: '设备维护和保养',
                        content: [
                            {
                                heads: '更换滤芯：',
                                contents: [
                                    '滤芯的寿命与水源水质、总用水量等多种因素有关。',
                                    '出现下列情况时，请及时更换滤芯:',
                                    '1、滤芯使用时间超过3个月，建议更换滤芯；',
                                    '2、过滤后的水有异味接近自来水，且通过净水出口放水冲洗后异味仍然存在；',
                                    '3、净水流量明显变小。'
                                ]
                            },
                            {
                                heads: '清洗外壳：',
                                contents: [
                                    '1、请用柔软的湿毛巾定期擦拭净水器外壳；',
                                    '2、 请勿使用钢丝球等坚硬的物体擦拭净水器外壳，以免对壳体造成损害;',
                                    '3、请勿使用肥皂、洗涤剂、稀释剂、酒精或腐蚀性液体来清洁净水器，以免对净水器造成损坏。'
                                ]
                            }
                        ]
                    }
                }
            ],
            productService: [
                {
                    product: '净水龙头',
                    id: '1',
                    maintenanceTime: '1年',
                    maintenanceRange: '制造缺陷或材料质量问题。',
                    maintenanceCondition: '需要出示有效的购买凭证，保持产品在规定的使用环境下使用，并按照产品说明书的要求进行正确的安装和维护。',
                    maintenanceType: '电话咨询、在线技术支持、产品送至指定的维修中心。',
                    maintenancerespons: '免费维修、更换受损部件或整个产品的替换。',
                    afterSales: '滤芯更换、维护建议、产品升级或替换等服务。',
                    maintenance: '非人为损坏',
                    repairProcess: [{ title: '保修申请', detail: '发现产品故障或需要维修时，填写在线报修申请表格或致电客户服务中心提出报修申请（产品信息、故障描述和联系方式）。' },
                    { title: '故障评估', detail: '客户服务团队收到报修申请后，进行初步故障评估，尽可能准确地判断故障原因，并决定下一步的处理方式。' },
                    { title: '维修建议', detail: '根据故障评估的结果，客户服务团队提供维修建议。' },
                    { title: '维修授权', detail: '如果需要将产品送至维修中心进行修理，我们需要客户授权维修操作（这可能涉及费用估计、维修时限和授权签署等事项）。' },
                    { title: '产品送修', detail: '如果客户接受维修建议，我们将被产品送至指定的维修中心（客户可以选择自行送修或要求安排快递服务进行取件）。' },
                    { title: '维修处理', detail: '维修中心接收到产品后，进行详细的故障诊断和维修操作。技术人员会修复或更换受损的零部件，并确保产品恢复正常运行。在维修过程中，客户可以随时向客户服务团队了解修理进展。' },
                    { title: '维修完成通知', detail: '一旦维修完成，客户服务团队将通过电话、电子邮件或短信等方式通知客户。我们将提供修理的详细报告、维修费用（如果适用）和产品取回的安排。' },
                    { title: '产品取回或返还', detail: '客户根据通知前往维修中心或等待产品送回。' },
                    { title: '满意度调查', detail: '在维修完成后，客户服务团队可能会进行满意度调查。我们会向客户提供反馈渠道，以了解客户对维修质量和服务体验的满意度，并根据反馈进行改进。' }],
                },
                {
                    product: '台式制冰机',
                    id: '2',
                    maintenanceTime: '3年',
                    maintenanceRange: '制造缺陷或材料质量问题。',
                    maintenanceCondition: '需要出示有效的购买凭证，保持产品在规定的使用环境下使用，并按照产品说明书的要求进行正确的安装和维护。',
                    maintenanceType: '电话咨询、在线技术支持、产品送至指定的维修中心。',
                    maintenancerespons: '免费维修、更换受损部件或整个产品的替换。',
                    afterSales: '滤芯更换、维护建议、产品升级或替换等服务。',
                    maintenance: '非人为损坏',
                    repairProcess: [{ title: '保修申请', detail: '发现产品故障或需要维修时，填写在线报修申请表格或致电客户服务中心提出报修申请（产品信息、故障描述和联系方式）。' },
                    { title: '故障评估', detail: '客户服务团队收到报修申请后，进行初步故障评估，尽可能准确地判断故障原因，并决定下一步的处理方式。' },
                    { title: '维修建议', detail: '根据故障评估的结果，客户服务团队提供维修建议。' },
                    { title: '维修授权', detail: '如果需要将产品送至维修中心进行修理，我们需要客户授权维修操作（这可能涉及费用估计、维修时限和授权签署等事项）。' },
                    { title: '产品送修', detail: '如果客户接受维修建议，我们将被产品送至指定的维修中心（客户可以选择自行送修或要求安排快递服务进行取件）。' },
                    { title: '维修处理', detail: '维修中心接收到产品后，进行详细的故障诊断和维修操作。技术人员会修复或更换受损的零部件，并确保产品恢复正常运行。在维修过程中，客户可以随时向客户服务团队了解修理进展。' },
                    { title: '维修完成通知', detail: '一旦维修完成，客户服务团队将通过电话、电子邮件或短信等方式通知客户。我们将提供修理的详细报告、维修费用（如果适用）和产品取回的安排。' },
                    { title: '产品取回或返还', detail: '客户根据通知前往维修中心或等待产品送回。' },
                    { title: '满意度调查', detail: '在维修完成后，客户服务团队可能会进行满意度调查。我们会向客户提供反馈渠道，以了解客户对维修质量和服务体验的满意度，并根据反馈进行改进。' }]
                }],
        },
        // 净水龙头
        Water: {
            name: '净水龙头',
            bannerImg: 'metapure/metapure_web/PC/water/water_banner1.png',
            menuList: [{key: 0, name: '概览'},{key: 1, name: '使用'},{key: 2, name: '技术参数'}],
            purchase: '购买',
            wuliguige: '物理规格',
            jibencanshu: '基本参数',
            height: '高度',
            width: '宽度',
            chang: '长度',
            weight: '重量',
            protype: '产品型号',
            jlsl: '静流水量',
            edzjsl: '额定总净水量',
            sysw: '适用水温',
            gzyl: '工作压力',
            azwz: '安装位置',
            chushui: '水龙头出水口',
            //移动端
            mBannerImg: 'metapure/metapure_web/Mobile/clearWater/cwbanner3.png',
        },
        icemake: {
            name: '台式制冰机',
            bannerImg: 'metapure/metapure_web/PC/iceMachine/ice_banner1.png',
            mBannerImg:'',
            menuList: [{ key: 0, name: '概览' }, { key: 1, name: '使用' }, { key: 2, name: '技术参数' }],
            purchase: '购买',
            wuliguige: '物理规格',
            jibencanshu: '基本参数',
            height: '高度',
            width: '宽度',
            chang: '长度',
            xianchang: '电源线长',
            proname: '产品名称',
            proType: '产品型号',
            sxrl: '水箱容量',
            edgl: '额定功率',
            edpl: '额定频率',
            eddy: '额定电压',
            pronamech: '美塔璞儿牌台式制冰机',
            //移动端
            mBannerImg: 'metapure/metapure_web/Mobile/clearWater/icebanner2.png',
        },
        //关于我们
        Aboutus: [
            {
                id: 0,
                title1: '合作共赢',
                title2: '10大政策支持，助力实现快速持续增长',
                oneSupport: [{ name: '市场分析', src: 'shichang2.png' }, { name: '品牌授权', src: 'pinpai2.png' }, { name: '产品体验', src: 'tiyan2.png' }, { name: '出样补贴', src: 'chuyang2.png' }, { name: '方案定制', src: 'dingzhi2.png' }],
                twoSupport: [{ name: '培训支持', src: 'peixun_2.png' }, { name: '线索支持', src: 'xiansuo2.png' }, { name: '规模扶持', src: 'fuchi2.png' }, { name: '多重激励', src: 'jili2.png' }, { name: '安心服务', src: 'fuwu2.png' }],
                company: '深圳美塔璞儿科技股份有限公司',
                companyAddress: [{ name: '商务经理', detail: '肖锋', icon: 'phone2.png', mIcon:'user.png' },
                { name: '商务合作', detail: '134 8077 5617', icon: 'user2.png', mIcon:'phone.png' },
                { name: '联系邮箱', detail: 'xiaofeng@metapure.group', icon: 'email2.png', mIcon:'email.png' },
                { name: '联系地址', detail: '深圳市龙华区龙华街道景龙社区龙华大道3639号环智中心C座15层1505、1506B', icon: 'address2.png', mIcon:'address.png' },
                ],
                // 移动端
                Supports: [
                    { name: '市场分析', src: 'shichang1.png' },
                    { name: '品牌授权', src: 'pinpai1.png' },
                    { name: '产品体验', src: 'tiyan1.png' },
                    { name: '出样补贴', src: 'chuyang1.png' },
                    { name: '方案定制', src: 'dingzhi1.png' },
                    { name: '培训支持', src: 'peixun_1.png' },
                    { name: '线索支持', src: 'xiansuo1.png' },
                    { name: '规模扶持', src: 'fuchi1.png' },
                    { name: '多重激励', src: 'jili1.png' },
                    { name: '安心服务', src: 'fuwu1.png' }
                ],

            },
            {
                id: 1,
                title3: '质量方针',
                qualityBanner: [
                    { name: '客户满意度', conten: '确保客户满意度是质量方针的核心。我们致力于提供高质量的产品和优质的服务，满足客户的需求和期望。', icon: 'manyi2.png' },
                    { name: '持续改进', conten: '质量方针应强调持续改进的重要性，我们建立改进的机制和流程，不断寻求优化产品、工艺和管理体系，以提高质量和效率。', icon: 'gaijin2.png' },
                    { name: '符合法规和标准', conten: '从产品安全性、环境保护、健康与安全等方面满足相应的法规和标准要求。', icon: 'fuhe2.png' },
                    { name: '团队合作和员工参与', conten: '鼓励团队合作和员工参与，营造积极的工作环境，鼓励员工提供质量改进的建议和参与质量管理活动。', icon: 'hezuo2.png' },
                    { name: '持续培训和提升', conten: '强调员工培训和能力提升的重要性，提供必要的培训机会，确保员工具备所需的技能和知识，以保证产品和服务的质量。', icon: 'peixun2.png' },
                    { name: '供应链管理', conten: '对供应链的管理要求，与供应商建立良好的合作关系，并确保供应链的质量控制和监督。', icon: 'guanli2.png' },
                    { name: '数据驱动决策', conten: '强调数据驱动决策的重要性，建立有效的数据收集和分析机制，以便基于数据做出决策和改进措施。', icon: 'juece2.png' }
                ],
                //移动端
                qualityBanner1: [
                    { name: '客户满意度', conten: '确保客户满意度是质量方针的核心。我们致力于提供高质量的产品和优质的服务，满足客户的需求和期望。', icon: 'manyi.png' },
                    { name: '持续改进', conten: '质量方针应强调持续改进的重要性，我们建立改进的机制和流程，不断寻求优化产品、工艺和管理体系，以提高质量和效率。', icon: 'gaijin.png' },
                    { name: '符合法规和标准', conten: '从产品安全性、环境保护、健康与安全等方面满足相应的法规和标准要求。', icon: 'fuhe.png' }
                ],
                qualityBanner2: [
                    { name: '团队合作和员工参与', conten: '鼓励团队合作和员工参与，营造积极的工作环境，鼓励员工提供质量改进的建议和参与质量管理活动。', icon: 'hezuo.png' },
                    { name: '持续培训和提升', conten: '强调员工培训和能力提升的重要性，提供必要的培训机会，确保员工具备所需的技能和知识，以保证产品和服务的质量。', icon: 'peixun1.png' },
                    { name: '供应链管理', conten: '对供应链的管理要求，与供应商建立良好的合作关系，并确保供应链的质量控制和监督。', icon: 'guanli.png' },
                    { name: '数据驱动决策', conten: '强调数据驱动决策的重要性，建立有效的数据收集和分析机制，以便基于数据做出决策和改进措施。', icon: 'juece.png' }
                ],
                buttonText1:'查看全部',
                buttonText2:'收起',
            },
            {
                id: 2,
                title4: '公司介绍',
                html: `<span style="color:#255D90">美塔璞儿metapure</span>，利用先进前沿的<span style="color:#255D90">高效净化、浓缩还原</span>等技术，让水回归纯净、回归天<br />
                然，向过滤纯净的水中添加天然矿物质，微量元素等，让<span style="color:#255D90">饮水用水更健康</span>。<br><br>
                <span style="color:#255D90">美塔璞儿集团</span>于2014年即创新定义了台式净饮机，极大便利了人们日常饮水。并依靠<span style="color:#255D90">产学</span><br />
                <span style="color:#255D90">研一体化</span>智能制造体系，坚持环保理念，以技术创新驱动，带来更多的健康产品，公司目前<br />有净水龙头、台式净饮机、制冰机、速热饮水机、气泡水机、冰水机等多系列净水饮水产<br />品。`,

                //移动端
                mHtml:`<div class="content" style="height:96rem;width: 335rem;font-size: 14rem;font-weight: 400;color: #212529;line-height: 24rem;margin-top: 16rem;"><span style="color:#255D90">美塔璞儿metapure</span>，利用先进前沿的<span style="color:#255D90">高效净化、浓缩还原</span>等技术，让水回归纯净、回归天然，向过滤纯净的水中添加天然矿物质，微量元素等，<span style="color:#255D90">让饮水用水更健康</span>。</div>
                <div class="content" style="height:144rem;width: 335rem;font-size: 14rem;font-weight: 400;color: #212529;line-height: 24rem;margin-top: 16rem;"><span style="color:#255D90">美塔璞儿集团</span>于2014年即创新定义了台式净饮机，极大便利了人们日常饮水。并依靠<span>产学研一体化</span>智能制造体系，坚持环保理念，以技术创新驱动，带来更多的健康产品，公司目前有净水龙头、台式净饮机、制冰机、速热饮水机、气泡水机、冰水机等多系列净水饮水产品。</div>`
            },
            {
                id: 3,
                menuList: [{ key: 0, name: '美塔璞儿' }, { key: 1, name: '合作共赢' }, { key: 2, name: '质量方针' }, { key: 3, name: '公司介绍' }]
            }
        ],
        //研究与发展
        research: [
            {
                id: 0,
                menuList: [{ key: 0, name: '美塔璞儿' }, { key: 1, name: '研究和创新' }, { key: 2, name: '可持续发展' }, { key: 3, name: '生产力与研发力' }]
            },
            {
                id: 1,
                title1: '研究和创新',
                yanjiuBanner: [
                    {
                        text: '深圳美塔璞儿 metapure 科技股份有限公司与中科院合肥固体物理研究院签署战略合作协议，并建立<span style="color:#255D90">“水资源利用新材料和新技术优尼雅工程中心”</span>，美塔璞儿制造技术稳健位于领先地位。',
                        src: 'metapure/metapure_web/PC/research/new2_1.png',
                        mSrc:'metapure/metapure_web/Mobile/research/yanjiu1.png',
                    },
                    {
                        text: '对标国际同类产品，<span style="color:#255D90">美塔璞儿5000L大通量</span>，综合性价比已达到和超越国际一流产品的技术！',
                        src: 'metapure/metapure_web/PC/research/new2_2.png',
                        mSrc:'metapure/metapure_web/Mobile/research/yanjiu2.png',
                    },
                    {
                        text: '美塔璞儿率先研发出矿泉水机，<span style="color:#255D90">模拟自然，天然矿化</span>，使水体富锶。',
                        src: 'metapure/metapure_web/PC/research/new3_3.png',
                        mSrc:'metapure/metapure_web/Mobile/research/yanjiu2_3.png',

                    },
                    {
                        text: '美塔璞儿<span style="color:#255D90">多种领先技术</span>，组合形成领先的制造能力，生产出优质产品。',
                        src: 'metapure/metapure_web/PC/research/new3_4.png',
                        mSrc:'metapure/metapure_web/Mobile/research/yanjiu2_4.png',
                    },
                    {
                        text: '持续坚持技术领先是核心竞争力的基础， <span style="color:#255D90">高新技术企业正在申报审批中</span>。',
                        src: 'metapure/metapure_web/PC/research/new2_5.png',
                        mSrc:'metapure/metapure_web/Mobile/research/yanjiu5.png',
                    },
                ],
            },
            {
                id: 2,
                title2: '可持续发展',
                sustainabilityList: [
                    { name: '守护自然 绿色发展', src: 'huanbao2.png', mSrc:'develop1.png' },
                    { name: '以人为本 和谐发展', src: 'moreuser2.png', mSrc:'develop2.png' },
                    { name: '科技助力 长足发展', src: 'zuji2.png', mSrc:'develop3.png' }
                ],
                check: '查看详情'
            },
            {
                id: 3,
                title3: '生产力与研发力',
                productivityList: [{ title: '东莞市优尼雅电子科技有限公司', src: 'jianzhu2_1.png', mSrc:'jianzhu1_1.png', html: '<p>优尼雅工厂建筑面积<span style="color:#255D90">2万多平方米</span>，净水器成品<span style="color:#255D90">月产量达到3万套</span>。</p>' },
                { title: '美塔璞儿(马鞍山)智能制造有限公司', src: 'jianzhu2_2.png', mSrc:'jianzhu1_2.png', html: '<p>马鞍山工厂占地面积<span style="color:#255D90">68亩</span>，建筑面积<span style="color:#255D90">6万平方</span>，是<span style="color:#255D90">6万平方</span>工业4.0智能制造工厂。</p>' },
                { title: '制造技术与支持', src: 'jianzhu2_3.png', mSrc:'jianzhu1_3.png', html: '<p>与中科院合肥固体物理研究院合作并建立“<span style="color:#255D90">水资源利用新材料和新技术优尼雅工程中心</span>”。</p>' },
                { title: '专利及认证', src: 'jianzhu2_4.png', mSrc:'jianzhu1_4.png', html: '<p>发明专利约<span style="color:#255D90">5项</span>，实用新型和外观专利<span style="color:#255D90">超30项</span>，获得<span style="color:#255D90">美国FDA认证</span>。</p>' }],
            }
        ],
        //可持续发展
        sustainability: [
            {
                id: 0,
                title1: '绿色环保',
                qualityBanner: [
                    { name: '环境友好产品', conten: '可以致力于研发和生产环境友好型的净水产品，并具备高效节能、低排放、材料环保等特点，减少对环境的负面影响。', icon: 'huan1.png' },
                    { name: '资源节约与循环利用', conten: '我们积极推动资源的节约与循环利用，在生产过程中，优化能源利用、减少水资源消耗，并积极推广废水的再利用和废弃物的合理处理。', icon: 'huan2.png' },
                    { name: '碳排放减少', conten: '使用低碳技术和设备，优化物流和运输方式，减少能源的消耗和碳排放。', icon: 'huan3.png' },
                    { name: '环境管理与认证', conten: '建立和实施环境管理体系，以确保对环境的有效管理和监控，通过设定环境目标和指标，并进行定期评估和改进，企业可以持续改善环境绩效。', icon: 'huan4.png' },
                    { name: '教育和宣传', conten: '开展环境教育和宣传活动，提高公众对环境保护的意识和重视程度，包括组织环境培训、参与社区环境保护项目，以及传达企业在绿色环保方面的努力和成果。', icon: 'huan5.png' },
                    { name: '合规与法规遵守', conten: '遵守环境相关的法律法规和标准要求，合规处理废水、废气、废物等，确保生产过程符合环保标准，并配合监管部门的环境检查和评估。', icon: 'huan6.png' }
                ],
                // 移动端
                protectionBanner1: [
                    { name: '环境友好产品', conten: '可以致力于研发和生产环境友好型的净水产品，并具备高效节能、低排放、材料环保等特点，减少对环境的负面影响。', icon: 'huanbao1.png' },
                    { name: '资源节约与循环利用', conten: '我们积极推动资源的节约与循环利用，在生产过程中，优化能源利用、减少水资源消耗，并积极推广废水的再利用和废弃物的合理处理。', icon: 'huanbao2.png' },
                    { name: '碳排放减少', conten: '使用低碳技术和设备，优化物流和运输方式，减少能源的消耗和碳排放。', icon: 'huanbao3.png' },

                ],
                protectionBanner2: [
                    { name: '环境管理与认证', conten: '建立和实施环境管理体系，以确保对环境的有效管理和监控，通过设定环境目标和指标，并进行定期评估和改进，企业可以持续改善环境绩效。', icon: 'huanbao4.png' },
                    { name: '教育和宣传', conten: '开展环境教育和宣传活动，提高公众对环境保护的意识和重视程度，包括组织环境培训、参与社区环境保护项目，以及传达企业在绿色环保方面的努力和成果。', icon: 'huanbao5.png' },
                    { name: '合规与法规遵守', conten: '遵守环境相关的法律法规和标准要求，合规处理废水、废气、废物等，确保生产过程符合环保标准，并配合监管部门的环境检查和评估。', icon: 'huanbao6.png' }
                ],
                buttonText1:'查看全部',
                buttonText2:'收起',
            },
            {
                id: 1,
                title2: '我们的行动',
                actionList: [
                { icon: 'action1.png', mIcon:'xingdong1.png',  title1: '产品', title2: '设计和制造', mx: '使用可再生材料、减少能源消耗、降低废弃物产生。', detail: '采用环境友好的设计和制造方法，可以确保产品在使用寿命内能够高效节能、降低碳排放，并可进行循环利用或安全回收处理。' },
                { icon: 'action2.png', mIcon:'xingdong2.png',  title1: '能源', title2: '管理', mx: '改善设备的能源效率、优化生产流程、使用节能设备。', detail: '实施能源管理措施，关注能源消耗高的环节，并采取措施降低能源的使用量，以减少对环境的负面影响。' },
                { icon: 'action3.png', mIcon:'xingdong3.png',  title1: '水资源', title2: '管理', mx: '采用节水设备、优化生产过程中的水循环利用、提高废水处理效率。', detail: '优化水资源的使用和管理，关注净水设备的节水性能，并在生产过程中寻求水资源的最佳利用方式。' },
                { icon: 'action4.png', mIcon:'xingdong4.png',  title1: '废物', title2: '管理与回收', mx: '分类收集、废物减量化、废物再利用。', detail: '实施有效的废物管理和回收措施，与专业机构合作，确保废物的安全处理和合规处置。' },
                { icon: 'action5.png', mIcon:'xingdong5.png',  title1: '碳排放', title2: '管理', mx: '用清洁能源、优化运输方式、推广电子化办公。', detail: '关注碳排放量，并采取措施减少企业的碳足迹，通过采用清洁能源、优化运输方式、推广电子化办公等方式减少碳排放。' }]
            }
        ],
        // 水质地图
        waterMap: {
            title: '全国水质情况',
            s1: '纯度高',
            s2: '纯度较高',
            s3: '易结水垢',
            s4: '水垢严重',
            s5: '口感差',
            s6: '不可饮用',
            w1: '水质优良',
            w2: '易结水垢',
            w3: '影响饮用口感',
            w4: '全省TDS水质情况',
            note:'注：以上数据来源于美塔璞儿物联网平台数据库。',
            waterType: [
                { color: '#9BF2E4', name: '纯度高' },
                { color: '#7BCEF2', name: '纯度较高' },
                { color: '#EBC963', name: '易结水垢' },
                { color: '#F2874A', name: '水垢严重' },
                { color: '#D34D10', name: '口感差' },
                { color: '#740000', name: '不可饮用' }
            ],
            waterData: [
                {
                    name: "海南省",
                    value: 69,
                    values: [83.33, 0, 16.67],
                    ranking: "全国第一",
                },
                {
                    name: "浙江省",
                    value: 72,
                    values: [76.68, 0, 23.32],
                    ranking: "全国第二",
                },
                {
                    name: "江西省",
                    value: 78,
                    values: [76.68, 0, 23.12],
                    ranking: "全国第三",
                },
                {
                    name: "福建省",
                    value: 88,
                    values: [58.78, 0, 41.22],
                    ranking: "全国第四",
                },
                {
                    name: "广东省",
                    value: 103,
                    values: [65.06, 0.15, 34.79],
                    ranking: "全国第五",
                },
                {
                    name: "广西壮族自治区",
                    value: 119,
                    values: [35.62, 0, 64.38],
                    ranking: "全国第六",
                },
                {
                    name: "云南省",
                    value: 127,
                    values: [39.17, 0, 60.83],
                    ranking: "全国第七",
                },
                {
                    name: "湖南省",
                    value: 129,
                    values: [32.42, 0, 67.58],
                    ranking: "全国第八",
                },
                {
                    name: "黑龙江省",
                    value: 134,
                    values: [54.9, 0, 45.1],
                    ranking: "全国第九",
                },
                {
                    name: "辽宁省",
                    value: 154,
                    values: [33.52, 0, 66.48],
                    ranking: "全国第十",
                },
                {
                    name: "吉林省",
                    value: 160,
                    values: [19.21, 0, 80.79],
                    ranking: "全国第十一",
                },
                {
                    name: "湖北省",
                    value: 174,
                    values: [13.76, 0, 86.24],
                    ranking: "全国第十二",
                },
                {
                    name: "贵州省",
                    value: 178,
                    values: [11.82, 0.91, 87.27],
                    ranking: "全国第十三",
                },
                {
                    name: "四川省",
                    value: 182,
                    values: [1.45, 0, 98.55],
                    ranking: "全国第十四",
                },
                {
                    name: "天津市",
                    value: 190,
                    values: [2.94, 0, 97.06],
                    ranking: "全国第十五",
                },
                {
                    name: "重庆市",
                    value: 197,
                    values: [9.24, 0, 90.76],
                    ranking: "全国第十六",
                },
                {
                    name: "陕西省",
                    value: 206,
                    values: [14.89, 0, 85.11],
                    ranking: "全国第十七",
                },
                {
                    name: "安徽省",
                    value: 209,
                    values: [31.47, 2.8, 65.73],
                    ranking: "全国第十八",
                },
                {
                    name: "新疆维吾尔自治区",
                    value: 216,
                    values: [10, 2.5, 87.5],
                    ranking: "全国第十九",
                },
                {
                    name: "北京市",
                    value: 218,
                    values: [0, 0, 100],
                    ranking: "全国第二十",
                },
                {
                    name: "江苏省",
                    value: 220,
                    values: [1.28, 0.37, 98.35],
                    ranking: "全国第二十一",
                },
                {
                    name: "青海省",
                    value: 222,
                    values: [0, 0, 100],
                    ranking: "全国第二十二",
                },
                {
                    name: "西藏自治区",
                    value: 262,
                    values: [0, 0, 100],
                    ranking: "全国第二十三",
                },
                {
                    name: "上海市",
                    value: 267,
                    values: [0, 0, 100],
                    ranking: "全国第二十四",
                },
                {
                    name: "河北省",
                    value: 270,
                    values: [1.99, 2.79, 95.22],
                    ranking: "全国第二十五",
                },
                {
                    name: "河南省",
                    value: 271,
                    values: [4.45, 5.64, 89.91],
                    ranking: "全国第二十六",
                },
                {
                    name: "内蒙古自治区",
                    value: 279,
                    values: [7.69, 1.1, 91.21],
                    ranking: "全国第二十七",
                },
                {
                    name: "甘肃省",
                    value: 284,
                    values: [0, 0, 100],
                    ranking: "全国第二十八",
                },
                {
                    name: "山西省",
                    value: 333,
                    values: [0, 2.12, 97.88],
                    ranking: "全国第二十九",
                },
                {
                    name: "宁夏回族自治区",
                    value: 335,
                    values: [0, 0, 100],
                    ranking: "全国第三十",
                },
                {
                    name: "山东省",
                    value: 338,
                    values: [1.33, 3.15, 95.52],
                    ranking: "全国第三十一",
                },
            ]
        },
        //移动端
        //移动端导航栏
        mNavTop:[
            { name: '台式制冰机', path: '/mobile/iceMachine' },
            { name: '净水龙头', path: '/mobile/clearWater' },
            { name: '水质地图', path: '/mobile/waterMap' },
            { name: '研究与发展', path: '/mobile/research/mResearch' },
            { name: '服务中心', path: '/mobile/service/index' },
            { name: '关于我们', path: '/mobile/mAbout' },
        ],
        //首页
        mIndex:{
            //产品轮播
            mProBanners:[
                { name: '台式制冰机', imgSrc: 'metapure/metapure_web/Mobile/home/proBanners1.png', path: '/mobile/iceMachine', note: '10分钟出冰，冰块高密无气泡，冰力强劲不易融，小巧智能，品质好冰随时享。' },
                { name: '净水龙头', imgSrc: 'metapure/metapure_web/Mobile/home/proBanners2.png', path: '/mobile/clearWater', note: '航空铝可视化，陶瓷复合滤芯，4重过滤，让水质回归纯净。' }
            ],
            //大家这样说
            talksTitle:'大家这样说',
            talksList: [
                { userName: '大香子', userImg: 'metapure/metapure_web/Mobile/home/talks/userImg1.png', proImg: 'metapure/metapure_web/Mobile/home/talks/proImg1.png', talk: '出冰挺快的，时间也能调节，想要什么厚度的冰块都可以，制的冰是方冰，融化的也比普通冰块慢一些～', level: [4, 1] },
                { userName: 'Mandy jing', userImg: 'metapure/metapure_web/Mobile/home/talks/userImg3_1.png', proImg: 'metapure/metapure_web/Mobile/home/talks/proImg3_1.png', talk: '自带保温功能，也不怕老是要等制冰，想做冰饮，随时都能取，也不需要等，这个制冰机买的值！', level: [5, 0] },
                { userName: '蜡笔小熊', userImg: 'metapure/metapure_web/Mobile/home/talks/userImg2.png', proImg: 'metapure/metapure_web/Mobile/home/talks/proImg2.png', talk: '对我家来说已经足够用了,15分钟就有冰出来,加一次水可以出来几板冰块,家里做做饮料、做做菜都要足够了。', level: [4, 1] },
                // {userName:'',userImg:'',proImg:'',talk:'',level:[]},
            ],
            //品牌介绍
            brand:{
                brandTitle1:'回归本真，天然纯净',
                brandTitle2:'美好的生活，健康来定义',
                p1:'品牌介绍。',
                p2:'美塔璞儿，关注人类饮水用水健康。以水感知生活，引领新的生活方式，带来更多的创新健康产品。',
                p3:'了解更多'
            },
            // 关于我们
            Aboutus:{
                usTitle:'METAPURE: BEYOND WATER',
                usP1:'我们的理念：回归本真，纯净天然。',
                usP2:'美好的生活，健康来定义。',
                usP3:'美塔璞儿',
                usP4:'metapure',
                usP5:'致力于向全世界消费者提供纯净、健康的水，以水感知生活，',
                usP6:'引领新的健康生活方式。',
                usP7:'了解更多',
            },
        }
    },

    EN: {
        //头部菜单
        menu: [
            { name: 'Metapure', path: '/home' }, { name: 'Water Purifier Faucet', path: '/product/clearWater' }, { name: 'Bench Ice Maker', path: '/product/iceMachine' }, { name: 'Water Quality Map', path: '/waterMap' }, { name: 'Research And Development', path: '/research' }, { name: 'Service Center', path: '/service' }, { name: 'About Us', path: '/about' }
        ],
        //底部菜单
        btMenu: {
            wxCode:'metapure/metapure_web/PC/home/EN/wx_CodeEn.png',
            stCode:'metapure/metapure_web/PC/home/EN/st_CodeEn.png',
            dyCode:'metapure/metapure_web/PC/home/EN/dy_CodeEn.png',
            title1: 'Product Center',
            title2: 'Service Center',
            title3: 'Research And Development',
            title4: 'About Us',
            btabout: '© 2016-2018 Metapure Corporation Shenzhen Metapure Technology Co., Ltd. Copyright｜ Guangdong ICP No. 2023093252-1',
            proCenter: [
                { name: 'Bench Ice Maker', path: '/product/iceMachine' },
                { name: 'Water Purifier Faucet', path: '/product/clearWater' },
            ],
            Service: [
                { name: 'Service Items', path: '/service', key: '0' },
                { name: 'Contact Us', path: '/service', key: '1' },
                // { name: 'Service activities', path: '/service', key: '1' },
            ],
            Research: [
                { name: 'Research And Innovation', path: '/research', key: '0' },
                { name: 'Sustainable Development', path: '/research', key: '1' },
                { name: 'Productivity And R&D', path: '/research', key: '2' },
            ],
            About: [
                { name: 'About Metapure', path: '/about', key: '0' },
                { name: 'Win-win', path: '/about', key: '1' },
                { name: 'Quality Policy', path: '/about', key: '2' },
                { name: 'Who We Are', path: '/about', key: '3' },
            ],
        },
        //首页
        homeIndex:{
            banners: [
                { name: 'Bench Ice Maker', src: 'metapure/metapure_web/PC/home/EN/proBannerEn2_1.png', path: '/product/iceMachine' },
                { name: 'Water Purifier Faucet', src: 'metapure/metapure_web/PC/home/EN/proBannerEn2_2.png', path: '/product/clearWater' },
            ],
            talkList: [
                { id: '1', srcImg: 'metapure/metapure_web/PC/home/talkList/EN/talksEn2_1.png' },
                { id: '2', srcImg: 'metapure/metapure_web/PC/home/talkList/EN/talksEn2_2.png' },
                { id: '3', srcImg: 'metapure/metapure_web/PC/home/talkList/EN/talksEn2_3.png' },
                // { id: '4', srcImg: 'metapure/metapure_web/PC/home/talkList/talks4.png' },
            ],
        },
        //服务中心首页
        service: {
            title: 'Service Center',
            lianxi: 'Contact Us',
            kefu: 'Online Service',
            Code:'metapure/metapure_web/PC/service/En/wx_CodeEn1.png',
            hujiao:'call',
            fuzhi:'copy',
            Center: [
                { name: `Product warranty <br /> and repair`,src: 'metapure/metapure_web/PC/service/list1_1.png', note: `Professional technical personnel <br /> with guaranteed service`, path: '/service/productMaintenance', mPath:'/mobile/service/productStand',
                //移动端
                mName:`Product warranty and repair`,
                mNote: `Professional technical personnel with guaranteed service`,
                },
                { name: `Installation guidance <br /> and support`, src: 'metapure/metapure_web/PC/service/list1_2.png', note: `Installation Guide/Video <br /> Tutorial`, path: '/service/productInstall', mPath:'/mobile/service/productInstall',
                //移动端
                mName:`Installation guidance and support`,
                mNote: `Installation Guide/Video Tutorial`,
                },
                { name: `Filter element replacement <br /> and maintenance`, src: 'metapure/metapure_web/PC/service/list1_3.png', note: `Professional guidance, maintenance <br /> and upkeep of your equipment`, path: '/service/porudctReplacement', mPath:'/mobile/service/filterReplace',
                //移动端
                mName:`Filter element replacement and maintenance`,
                mNote: `Professional guidance, maintenance and upkeep of your equipment`,
                },
            ],
            ct: {
                ts1: 'Metapure Official Website',
                ts2: 'Service Center',
                ts3: 'Product warranty and maintenance',
                ts4: 'Warranty policy',
                ts5: 'Warranty period:',
                ts6: 'Warranty coverage:',
                ts7: 'Warranty conditions:',
                ts8: 'Warranty service method:',
                ts9: 'Warranty Liability:',
                ts10: 'After sales support:',
                ts11: 'Warranty Exclusion',
                ts12: 'After sales support:',
                ts13: 'Repair process',
                ts14: 'Installation guidance and support',
                ts15: 'Filter element replacement and maintenance',
                ts16: 'Video Tutorials',
                videomx: 'If you are still unable to install according to the installation guide and video tutorial, you can contact the brand‘s 400 phone number for online video guidance.'
            },
            proInstall: {
                title: 'Product Installation Guide',
                titles: 'Install using an adapter (faucet with a bubbler)',
                s1: 'Remove the bubbler from the faucet.',
                s2: 'Remove the connecting nuts from the Water filter.',
                s3: 'Thread the adapter into the sealing ring through the connecting nut and secure it on the faucet.',
                s4: 'Tighten the connecting nut on the Water filter and install it.',
                titles2: 'Install using a universal joint (faucet without a bubbler)',
                w1: 'Remove the connecting nuts from the Water filter.',
                w2: 'Select the M22 internal thread adapter, thread the adapter installed with the sealing ring through the connecting nut and tighten it with the universal joint.',
                w3: 'Install the universal joint on the faucet and tighten the tie screw.',
                w4: 'Tighten the connecting nut on the Water filter and install it.',
                waring: 'Note: When the outer diameter of the faucet is 15-19mm, directly install the universal joint; When the outer diameter of the faucet is 19-24mm, it is necessary to remove the rubber ring from the universal joint and then install it.',
                zbs1: 'Step 1',
                zbs1_1: 'Enter “metapure” in the “Search” box on the WeChat page, and click to enter the “metapure” mini program authorization login page.',
                zbs1_2: 'By clicking the "Scan" button on WeChat and scanning the QR code on the scan page, you can automatically jump to the authorized login page.',
                zbs2: 'Step 2',
                zbs2_1: 'Authorize to enter the mini program, click on the top left corner“',
                zbs2_2: '”Icon, scan the body QR code for binding.',
                zbs3: 'Step 3',
                zbs3_1: 'Click on "Confirm Binding" and complete the binding operation.'
            },
            porudctReplacement: [
                {
                    product: '净水龙头',
                    id: '1',
                    replacementFrequency: {
                        head: 'Frequency of filter element replacement',
                        content: [
                            'Clean faucet: Replace every 6 months.',
                            'Purchase channel: Search for the “metapure” mini program on WeChat to select filter elements.'
                        ]
                    },
                    replacementStep: {
                        head: 'Filter element replacement steps',
                        content: [
                            'metapure/metapure_web/PC/service/replacement/En/StepEn2_1.png'
                        ],
                        mContent:[
                            'metapure/metapure_web/Mobile/service/En/replaceEn.png'
                        ]
                    },
                    maintain: {
                        head: 'Equipment maintenance and upkeep',
                        content: [
                            {
                                heads: 'Replacing the filter element:',
                                contents: [
                                    'The lifespan of the filter element is related to various factors such as water quality of the source and total water consumption.',
                                    'Please replace the filter element in a timely manner when the following situations occur:',
                                    '1. If the filter cartridge has been in use for more than 3 months, it is recommended to replace the filter cartridge;',
                                    '2. The filtered water has a odor that is close to tap water, and the odor still exists after flushing with water at the water purification outlet;',
                                    '3. The net water flow rate has significantly decreased.'
                                ]
                            },
                            {
                                heads: 'Cleaning the shell:',
                                contents: [
                                    '1. Please regularly wipe the shell of the Water filter with a soft wet towel;',
                                    '2. Do not use hard objects such as steel wire balls to wipe the shell of the Water filter to avoid damage to the shell;',
                                    '3. Do not use soap, detergent, diluent, alcohol or corrosive liquid to clean the Water filter to avoid damage to the Water filter.'
                                ]
                            }
                        ]
                    }
                }
            ],
            productService: [
                {
                    product: 'Water Purifier Faucet',
                    id: '1',
                    maintenanceTime: '1 year',
                    maintenanceRange: 'Manufacturing defects or material quality issues.',
                    maintenanceCondition: 'It is necessary to present valid purchase vouchers, maintain the product in the specified usage environment, and carry out correct installation and maintenance according to the requirements of the product manual.',
                    maintenanceType: 'Telephone consultation, online technical support, and product delivery to designated repair centers.',
                    maintenancerespons: 'Free repair, replacement of damaged parts or replacement of the entire product.',
                    afterSales: 'Services such as filter replacement, maintenance recommendations, product upgrades or replacements.',
                    maintenance: 'Non human damage',
                    repairProcess: [{ title: 'Warranty claim', detail: 'When a product malfunction or repair is found, fill out the online repair application form or call the customer Service Center to submit a repair application (product information, fault description, and contact information).' },
                    { title: 'Fault assessment', detail: 'After receiving the repair request, the customer service team conducts a preliminary fault assessment to determine the cause of the fault as accurately as possible and decide on the next steps to be taken.' },
                    { title: 'Maintenance recommendations', detail: 'Based on the results of the fault assessment, the customer service team provides repair suggestions.' },
                    { title: 'Repair Authorization', detail: 'If the product needs to be sent to a repair center for repair, we need the customer to authorize the repair operation (which may involve cost estimation, repair time limit, and authorization signing, etc.).' },
                    { title: 'Product repair', detail: 'If the customer accepts the repair suggestion, we will send the product to the designated repair center (customers can choose to send it for repair themselves or request to arrange a courier service for pick-up).' },
                    { title: 'Repair processing', detail: 'After receiving the product, the maintenance center conducts detailed fault diagnosis and repair operations. Technicians will repair or replace damaged components and ensure that the product returns to normal operation. During the repair process, customers can keep track of the repair progress with the customer service team at any time.' },
                    { title: 'Repair completion notification', detail: 'Once the repair is completed, the customer service team will notify the customer via phone, email, or text message. We will provide a detailed report on the repair, repair costs (if applicable), and arrangements for product retrieval.' },
                    { title: 'Product retrieval or return', detail: 'Customers are notified to go to the repair center or wait for the product to be returned. ' },
                    { title: 'Satisfaction survey', detail: 'After the repair is completed, the customer service team may conduct a satisfaction survey. We will provide feedback channels to customers to understand their satisfaction with repair quality and service experience, and make improvements based on feedback.' }]
                },
                {
                    product: 'Bench Ice Maker',
                    id: '2',
                    maintenanceTime: '3 year',
                    maintenanceRange: 'Manufacturing defects or material quality issues.',
                    maintenanceCondition: 'It is necessary to present valid purchase vouchers, maintain the product in the specified usage environment, and carry out correct installation and maintenance according to the requirements of the product manual.',
                    maintenanceType: 'Telephone consultation, online technical support, and product delivery to designated repair centers.',
                    maintenancerespons: 'Free repair, replacement of damaged parts or replacement of the entire product.',
                    afterSales: 'Services such as filter replacement, maintenance recommendations, product upgrades or replacements.',
                    maintenance: 'Non human damage',
                    repairProcess: [{ title: 'Warranty claim', detail: 'When a product malfunction or repair is found, fill out the online repair application form or call the customer Service Center to submit a repair application (product information, fault description, and contact information).' },
                    { title: 'Fault assessment', detail: 'After receiving the repair request, the customer service team conducts a preliminary fault assessment to determine the cause of the fault as accurately as possible and decide on the next steps to be taken.' },
                    { title: 'Maintenance recommendations', detail: 'Based on the results of the fault assessment, the customer service team provides repair suggestions.' },
                    { title: 'Repair Authorization', detail: 'If the product needs to be sent to a repair center for repair, we need the customer to authorize the repair operation (which may involve cost estimation, repair time limit, and authorization signing, etc.).' },
                    { title: 'Product repair', detail: 'If the customer accepts the repair suggestion, we will send the product to the designated repair center (customers can choose to send it for repair themselves or request to arrange a courier service for pick-up).' },
                    { title: 'Repair processing', detail: 'After receiving the product, the maintenance center conducts detailed fault diagnosis and repair operations. Technicians will repair or replace damaged components and ensure that the product returns to normal operation. During the repair process, customers can keep track of the repair progress with the customer service team at any time.' },
                    { title: 'Repair completion notification', detail: 'Once the repair is completed, the customer service team will notify the customer via phone, email, or text message. We will provide a detailed report on the repair, repair costs (if applicable), and arrangements for product retrieval.' },
                    { title: 'Product retrieval or return', detail: 'Customers are notified to go to the repair center or wait for the product to be returned. ' },
                    { title: 'Satisfaction survey', detail: 'After the repair is completed, the customer service team may conduct a satisfaction survey. We will provide feedback channels to customers to understand their satisfaction with repair quality and service experience, and make improvements based on feedback.' }]
                }],
        },
        // 净水龙头
        Water: {
            name: 'Water Purifier Faucet',
            bannerImg: 'metapure/metapure_web/PC/water/En/water_bannerEn2.png',
            menuList: [{ key: 0, name: 'overview' }, { key: 1, name: 'use' }, { key: 2, name: 'Technical Parameter' }],
            purchase: 'Purchase',
            wuliguige: 'Physical specifications',
            jibencanshu: 'Basic parameters',
            height: 'Height',
            width: 'Width',
            chang: 'Length',
            weight: 'Weight',
            protype: 'Model',
            jlsl: 'Static flow capacity',
            edzjsl: 'Rated total net water volume',
            sysw: 'Applicable water temperature',
            gzyl: 'Working pressure',
            azwz: 'Installation position',
            chushui: 'Faucet outlet',
            //移动端
            mBannerImg: 'metapure/metapure_web/Mobile/clearWater/En/cwbannerEn.png',
        },
        // 制冰机
        icemake: {
            name: 'Bench Ice Maker',
            bannerImg: 'metapure/metapure_web/PC/iceMachine/En/ice_bannerEn2.png',
            menuList: [{ key: 0, name: 'overview' }, { key: 1, name: 'use' }, { key: 2, name: 'Technical Parameter' }],
            purchase: 'Purchase',
            wuliguige: 'Physical specifications',
            jibencanshu: 'Basic parameters',
            height: 'Height',
            width: 'Width',
            chang: 'Length',
            xianchang: 'Power cord length',
            proname: 'Product name',
            proType: 'Model',
            sxrl: 'Water tank capacity',
            edgl: 'Rated power',
            edpl: 'Rated frequency',
            eddy: 'Rated voltage',
            pronamech: 'Metapure Bench Ice Maker',
            //移动端
            mBannerImg: 'metapure/metapure_web/Mobile/clearWater/En/icebannerEn.png',
        },
        //关于我们
        Aboutus: [
            {
                id: 0,
                title1: 'Win-win cooperation',
                title2: '10 major policy supports to help achieve rapid and sustained growth',
                oneSupport: [
                    { name: 'Market analysis', src: 'shichang2.png' },
                    { name: 'Brand authorized', src: 'pinpai2.png' },
                    { name: 'Producexperience', src: 'tiyan2.png' },
                    { name: 'Sample subsidy', src: 'chuyang2.png' },
                    { name: 'Program customization', src: 'dingzhi2.png' }
                ],
                twoSupport: [
                    { name: 'Training support', src: 'peixun_2.png' },
                    { name: 'Clue support', src: 'xiansuo2.png' },
                    { name: 'Scale support', src: 'fuchi2.png' },
                    { name: 'Multiple incentives', src: 'jili2.png' },
                    { name: 'Peace of mind service', src: 'fuwu2.png' }
                ],
                company: 'Shenzhen Metapure Technology Co., Ltd.',
                companyAddress: [
                    { name: 'Business manager', detail: 'Xiao Feng', icon: 'phone2.png', mIcon:'user.png'},
                    { name: 'Business Cooperation', detail: '134 8077 5617', icon: 'user2.png', mIcon:'phone.png' },
                    { name: 'Contact email', detail: 'xiaofeng@metapure.group', icon: 'email2.png', mIcon:'email.png' },
                    { name: 'Contact address', detail: '1505, 1506B,15th Floor,Building C,Huanzhi Center,No.3639,Longhua Avenue,Longhua Street,LonghuStreet,Longhua District,Shenzhen', icon: 'address2.png', mIcon:'address.png' },
                ],
                // 移动端
                Supports: [
                    { name: 'Market analysis', src: 'shichang1.png' },
                    { name: 'Brand authorized', src: 'pinpai1.png' },
                    { name: 'producexperience', src: 'tiyan1.png' },
                    { name: 'Sample subsidy', src: 'chuyang1.png' },
                    { name: 'Program customization', src: 'dingzhi1.png' },
                    { name: 'training support', src: 'peixun_1.png' },
                    { name: 'clue support', src: 'xiansuo1.png' },
                    { name: 'Scale support', src: 'fuchi1.png' },
                    { name: 'multiple incentives', src: 'jili1.png' },
                    { name: 'Peace of mind service', src: 'fuwu1.png' }
                ],
            },
            {
                id: 1,
                title3: 'Quality policy',
                qualityBanner: [
                    { name: 'Customer satisfaction', conten: 'Ensuring customer satisfaction is at the heart of our quality policy. We are committed tproviding high-quality products and excellent services to meet the needs and expectations of our customers.', icon: 'manyi2.png' },
                    { name: 'Keep improve', conten: 'The quality policy should emphasize the importance of continuous improvement, we establish improvemechanisms and processes, and constantly seek to optimize products, processes and management systems to improve quality and efficienc', icon: 'gaijin2.png' },
                    { name: 'Compliance with regulations and standards', conten: 'Meet the corresponding regulations and standards in terms of producsafety, environmental protection, health and safety.', icon: 'fuhe2.png' },
                    { name: 'Teamwork and employee engagement', conten: 'Encourage teamwork and employee participation, create a positive workinenvironment, and encourage employees to provide quality improvement suggestions and participate in quality management activities.', icon: 'hezuo2.png' },
                    { name: 'Continuous training and improvement', conten: 'Emphasize the importance of employee training and ability improvement, providnecessary training opportunities, and ensure that employees have the required skills and knowledge to ensure the quality of products anservices.', icon: 'peixun2.png' },
                    { name: 'Supply chain management', conten: 'For the management requirements of the supply chain, establish a good cooperativrelationship with suppliers, and ensure the quality control and supervision of the supply chain.', icon: 'guanli2.png' },
                    { name: 'Data driven decisions', conten: 'Emphasize the importance of data-driven decision-making, and establish effective datcollection and analysis mechanisms to make decisions and improve measures based on data.', icon: 'juece2.png' }
                ],
                //移动端
                qualityBanner1: [
                    { name: 'Customer satisfaction', conten: 'Ensuring customer satisfaction is at the heart of our quality policy. We are committed tproviding high-quality products and excellent services to meet the needs and expectations of our customers.', icon: 'manyi.png' },
                    { name: 'Keep improve', conten: 'The quality policy should emphasize the importance of continuous improvement, we establish improvemechanisms and processes, and constantly seek to optimize products, processes and management systems to improve quality and efficienc', icon: 'gaijin.png' },
                    { name: 'Compliance with regulations and standards', conten: 'Meet the corresponding regulations and standards in terms of producsafety, environmental protection, health and safety.', icon: 'fuhe.png' },
                 ],
                qualityBanner2: [
                    { name: 'Teamwork and employee engagement', conten: 'Encourage teamwork and employee participation, create a positive workinenvironment, and encourage employees to provide quality improvement suggestions and participate in quality management activities.', icon: 'hezuo.png' },
                    { name: 'Continuous training and improvement', conten: 'Emphasize the importance of employee training and ability improvement, providnecessary training opportunities, and ensure that employees have the required skills and knowledge to ensure the quality of products anservices.', icon: 'peixun1.png' },
                    { name: 'Supply chain management', conten: 'For the management requirements of the supply chain, establish a good cooperativrelationship with suppliers, and ensure the quality control and supervision of the supply chain.', icon: 'guanli.png' },
                    { name: 'Data driven decisions', conten: 'Emphasize the importance of data-driven decision-making, and establish effective datcollection and analysis mechanisms to make decisions and improve measures based on data.', icon: 'juece.png' }
                ],
                buttonText1:'View All',
                buttonText2:'Put Away',
            },
            {
                id: 2,
                title4: 'Company Profile',
                html: `<span style="color:#255D90">Metapure</span>, uses advanced and cutting-edge <span style="color:#255D90">igh-efficiency purificationconcentration reduction</span> and other technologies to return water to purity and nature, and adds natural minerals and trace elements tfiltered and pure water<span style="color:#255D90"> to make drinking water healthier</span>.<br><br>
                In 2014,<span style="color:#255D90">Metapure Group</span> innovatively defined the desktop drinking fountain, which greatly facilitatepeople's daily drinking water. And relying on the intelligent manufacturing system <span style="color:#255D90">integrating production , learning and research</span>, adhere to the concept of environmental protection,driven by technological innovation, bring more healthproducts,the company currently has water purification faucet, desktop drinking machine, ice machine, quick hot water dispenser, sparklinwater machine, ice water machine and other series of water purification and drinking water products.`,

                //移动端
                mHtml:`<div class="content" style="min-height:96rem;width: 335rem;font-size: 14rem;font-weight: 400;color: #212529;line-height: 24rem;margin-top: 16rem;"><span style="color:#255D90">Metapure</span>, uses advanced and cutting-edge <span style="color:#255D90">igh-efficiency purificationconcentration reduction</span> and other technologies to return water to purity and nature, and adds natural minerals and trace elements tfiltered and pure water<span style="color:#255D90"> to make drinking water healthier</span>.</div>
                <div class="content" style="min-height:144rem;width: 335rem;font-size: 14rem;font-weight: 400;color: #212529;line-height: 24rem;margin-top: 16rem;">In 2014,<span style="color:#255D90">Metapure Group</span> innovatively defined the desktop drinking fountain, which greatly facilitatepeople's daily drinking water. And relying on the intelligent manufacturing system <span style="color:#255D90">integrating production , learning and research</span>, adhere to the concept of environmental protection,driven by technological innovation, bring more healthproducts,the company currently has water purification faucet, desktop drinking machine, ice machine, quick hot water dispenser, sparklinwater machine, ice water machine and other series of water purification and drinking water products.</div>`
            },
            {
                id: 3,
                menuList: [{ key: 0, name: 'Metapure' }, { key: 1, name: 'Win-win' }, { key: 2, name: 'Quality policy' }, { key: 3, name: 'CompanProfile' }]
            }
        ],
        //研究与发展
        research: [
            {
                id: 0,
                menuList: [
                    { key: 0, name: 'Metapure' },
                    { key: 1, name: 'Research And Innovation' },
                    { key: 2, name: 'Sustainable Development' },
                    { key: 3, name: 'Productivity And R&D' }]
            },
            {
                id: 1,
                title1: 'Research And Innovation',
                yanjiuBanner: [
                    {
                        text: `Shenzhen Metapure Technology Co., Ltd. signed a strategic cooperation agreement with the Hefei Institute of Solid StatPhysics, Chinese Academy of Sciences, and established the <span style="color:#255D90">"Water Resources Utilization New Materials anNew Technology Unia Engineering Center"</span> , and Metapure's manufacturing technology is stable and in a leading position.`,
                        src: 'metapure/metapure_web/PC/research/new2_1.png',
                        mSrc:'metapure/metapure_web/Mobile/research/yanjiu1.png',
                    },
                    {
                        text: `Benchmarking international similar products,<span style="color:#255D90">Metapure 5000L Large Flux</span> , the comprehensivcost performance has reached and surpassed the technology of world-class products!`,
                        src: 'metapure/metapure_web/PC/research/En/newEn2.png',
                        mSrc:'metapure/metapure_web/Mobile/research/En/yanjiuEn2.png',
                    },
                    {
                        text: `Metapure took the lead in developing the mineral water machine,<span style="color:#255D90"> simulate nature , naturamineralization</span> enrich the water body with strontium.`,
                        src: 'metapure/metapure_web/PC/research/En/newEn3.png',
                        mSrc:'metapure/metapure_web/Mobile/research/En/yanjiuEn3.png',
                    },
                    {
                        text: `Metapure<span style="color:#255D90"> a variety of leading technologies</span> , the combination forms a leading manufacturincapability to produce high-quality products.`,
                        src: 'metapure/metapure_web/PC/research/En/newEn4.png',
                        mSrc:'metapure/metapure_web/Mobile/research/En/yanjiuEn4.png',
                    },
                    {
                        text: 'Continuous adherence to technological leadership is the basis of core competitiveness, <span style="color:#255D90"> High-tecenterprises are applying for approval</span>。',
                        src: 'metapure/metapure_web/PC/research/En/newEn5.png',
                        mSrc:'metapure/metapure_web/Mobile/research/En/yanjiuEn5.png',
                    },
                ],
            },
            {
                id: 2,
                title2: 'Sustainable Development',
                sustainabilityList: [
                    { name: `Protect nature <br /> and develop green`, src: 'huanbao2.png', mSrc:'develop1.png' },
                    { name: `People-oriented harmonious development`, src: 'moreuser2.png', mSrc:'develop2.png' },
                    { name: `Science and technology help rapid development`, src: 'zuji2.png', mSrc:'develop3.png' }
                ],
                check: 'Learn More'
            },
            {
                id: 3,
                title3: 'Productivity And R&D',
                productivityList: [
                    { title: 'Dongguan Youniya Electronic Technology Co., Ltd.', src: 'jianzhu2_1.png', mSrc:'jianzhu1_1.png', html: `<p>The construction area of the Younia factory is<span style="color:#255D90"> more than 20,000 square meters</span> ,and the monthly output of finished water purifiers<span style="color:#255D90">reaches 30,000 sets</span>.</p>` },
                    { title: `Metapure (Ma'anshan) Intelligent Manufacturing Co., Ltd.`, src: 'jianzhu2_2.png', mSrc:'jianzhu1_2.png', html: `<p>The Ma'anshan factory covers an area of <span style="color:#255D90"> 68 acres</span> ,with a construction area of <span style="color:#255D90">60,000 square meters</span> .It is a<span style="color:#255D90">60,000-square-meter</span> industrial 4.0 intelligent manufacturing factory.</p>` },
                    { title: 'Manufacturing Technology and Support Cooperate', src: 'jianzhu2_3.png', mSrc:'jianzhu1_3.png', html: `<p>Cooperated with the Hefei Institute of Solid State Physics, Chinese Academy of Sciences and established the " <span style="color:#255D90">Unia Engineering Center for New Materials and New Technologies for Water Resources Utilization</span>".</p>` },
                    { title: 'Patent and Certification', src: 'jianzhu2_4.png', mSrc:'jianzhu1_4.png', html: `<p><span style="color:#255D90">There are about 5</span> invention patents, <span style="color:#255D90">more than 30</span> utility model and appearance patents, and have been <span style="color:#255D90">certified by the US FDA</span>.</p>` }],
            }
        ],
        //可持续发展
        sustainability: [
            {
                id: 0,
                title1: 'Green',
                qualityBanner: [
                    { name: 'Environmentally friendly <br /> products', conten: 'We can devote ourselves to the Research And Development and production of environmentally friendly water purification products, which have the characteristics of high efficiency, energy saving, low emission, and environmentally friendly materials, so as to reduce the negative impact on the environment.', icon: 'huan1.png' },
                    { name: 'Resource conservation <br /> and recycling', conten: 'We actively promote the conservation and recycling of resources. In the production process, we optimize energy utilization, reduce water consumption, and actively promote the reuse of wastewater and the rational treatment of waste.', icon: 'huan2.png' },
                    { name: 'Carbon reduction', conten: 'Use low-carbon technologies and equipment, optimize logistics and transportation methods, and reduce energy consumption and carbon emissions.', icon: 'huan3.png' },
                    { name: 'Environmental management <br /> and certification', conten: 'Establish and implement an environmental management system to ensure effective management and monitoring of the environment. By setting environmental goals and indicators, and conducting regular assessment and improvement, enterprises can continuously improve their environmental performance.', icon: 'huan4.png' },
                    { name: 'Education and advocacy', conten: 'Carry out environmental education and publicity activities to increase public awareness and attention to environmental protection, including organizing environmental training, participating in community environmental protection projects, and communicating the efforts and achievements of enterprises in green environmental protection.', icon: 'huan5.png' },
                    { name: 'Compliance and regulatory <br /> compliance', conten: 'Comply with environment-related laws, regulations and standard requirements, treat waste water, waste gas, waste, etc. in compliance with regulations, ensure that the production process meets environmental protection standards, and cooperate with environmental inspections and assessments by regulatory authorities.', icon: 'huan6.png' }
                ],
                // 移动端
                protectionBanner1: [
                    { name: 'Environmentally friendly products', conten: 'We can devote ourselves to the Research And Development and production of environmentally friendly water purification products, which have the characteristics of high efficiency, energy saving, low emission, and environmentally friendly materials, so as to reduce the negative impact on the environment.', icon: 'huanbao1.png' },
                    { name: 'Resource conservation and recycling', conten: 'We actively promote the conservation and recycling of resources. In the production process, we optimize energy utilization, reduce water consumption, and actively promote the reuse of wastewater and the rational treatment of waste.', icon: 'huanbao2.png' },
                    { name: 'Carbon reduction', conten: 'Use low-carbon technologies and equipment, optimize logistics and transportation methods, and reduce energy consumption and carbon emissions.', icon: 'huanbao3.png' },
                ],
                protectionBanner2: [
                    { name: 'Environmental management and certification', conten: 'Establish and implement an environmental management system to ensure effective management and monitoring of the environment. By setting environmental goals and indicators, and conducting regular assessment and improvement, enterprises can continuously improve their environmental performance.', icon: 'huanbao4.png' },
                    { name: 'Education and advocacy', conten: 'Carry out environmental education and publicity activities to increase public awareness and attention to environmental protection, including organizing environmental training, participating in community environmental protection projects, and communicating the efforts and achievements of enterprises in green environmental protection.', icon: 'huanbao5.png' },
                    { name: 'Compliance and regulatory compliance', conten: 'Comply with environment-related laws, regulations and standard requirements, treat waste water, waste gas, waste, etc. in compliance with regulations, ensure that the production process meets environmental protection standards, and cooperate with environmental inspections and assessments by regulatory authorities.', icon: 'huanbao6.png' }
                ],
                buttonText1:'View All',
                buttonText2:'Put Way',
            },
            {
                id: 1,
                title2: 'Our actions',
                actionList: [
                    { icon: 'action1.png', mIcon:'xingdong1.png', title1: 'Product ', title2: 'design and manufacturing', mx: 'Use renewable materials, reduce energy consumption, and reduce waste generation.', detail: 'Adopting environmentally friendly design and manufacturing methods can ensure that products can be energy-efficient, reduce carbon emissions, and can be recycled or safely recycled during their service life.' },
                    { icon: 'action2.png', mIcon:'xingdong2.png', title1: 'Energy ', title2: 'management', mx: 'Improve the energy efficiency of equipment, optimize production processes, use energy-saving equipment.', detail: 'Implement energy management measures, pay attention to links with high energy consumption, and take measures to reduce energy consumption to reduce the negative impact on the environment.' },
                    { icon: 'action3.png', mIcon:'xingdong3.png', title1: 'Water resource ', title2: 'management', mx: 'Adopt water-saving equipment, optimize water recycling in the production process, and improve wastewater treatment efficiency.', detail: 'Optimize the use and management of water resources, pay attention to the water-saving performance of water purification equipment, and seek the best use of water resources in the production process.' },
                    { icon: 'action4.png', mIcon:'xingdong4.png', title1: 'Waste ', title2: 'management and recycling', mx: 'Sorted collection, waste reduction, and waste reuse.', detail: 'Implement effective waste management and recycling measures and cooperate with professional institutions to ensure the safe handling and compliant disposal of waste.' },
                    { icon: 'action5.png', mIcon:'xingdong5.png', title1: 'Carbon emission ', title2: 'management', mx: 'Use clean energy, optimize transportation methods, and promote electronic office.', detail: 'Pay attention to carbon emissions, and take measures to reduce the carbon footprint of enterprises, and reduce carbon emissions by adopting clean energy, optimizing transportation methods, and promoting electronic office.' }]
            }
        ],
        // 水质地图
        waterMap: {
            title: 'National water quality situation',
            s1: 'High purity',
            s2: 'High purity',
            s3: 'Easy to scale',
            s4: 'Severe scale',
            s5: 'Poor taste',
            s6: 'Not drinkable',
            w1: 'Excellent water quality',
            w2: 'Easy to scale',
            w3: 'Affects the taste of drinking',
            w4: 'TDS water quality situation in the province',
            note:'Note: The above data comes from the database of Metapure Internet of Things platform.',
            waterType: [
                { color: '#9BF2E4', name: 'High purity' },
                { color: '#7BCEF2', name: 'High purity' },
                { color: '#EBC963', name: 'Easy to scale' },
                { color: '#F2874A', name: 'Severe scale' },
                { color: '#D34D10', name: 'Poor taste' },
                { color: '#740000', name: 'Not drinkable' }
            ],
            waterData: [
                {
                    name: "海南省",
                    enname: 'Hainan',
                    value: 69,
                    values: [83.33, 0, 16.67],
                    ranking: "Ranked first in the country",
                },
                {
                    name: "浙江省",
                    enname: 'Zhejiang Province',
                    value: 72,
                    values: [76.68, 0, 23.32],
                    ranking: "Ranked second in the country",
                },
                {
                    name: "江西省",
                    enname: 'Jiangxi Province',
                    value: 78,
                    values: [76.68, 0, 23.12],
                    ranking: "Ranked third in the country",
                },
                {
                    name: "福建省",
                    enname: 'Fujian Province',
                    value: 88,
                    values: [58.78, 0, 41.22],
                    ranking: "Ranked fourth in the country",
                },
                {
                    name: "广东省",
                    enname: 'Guangdong Province',
                    value: 103,
                    values: [65.06, 0.15, 34.79],
                    ranking: "Fifth in the country",
                },
                {
                    name: "广西壮族自治区",
                    enname: 'the Guangxi Zhuang Autonomous Region',
                    value: 119,
                    values: [35.62, 0, 64.38],
                    ranking: "Sixth in the country",
                },
                {
                    name: "云南省",
                    enname: 'Yunnan Province',
                    value: 127,
                    values: [39.17, 0, 60.83],
                    ranking: "Seventh in the country",
                },
                {
                    name: "湖南省",
                    enname: 'Hunan Province',
                    value: 129,
                    values: [32.42, 0, 67.58],
                    ranking: "Eighth in the country",
                },
                {
                    name: "黑龙江省",
                    enname: 'Heilongjiang Province',
                    value: 134,
                    values: [54.9, 0, 45.1],
                    ranking: "Ninth in China",
                },
                {
                    name: "辽宁省",
                    enname: 'Liaoning Province',
                    value: 154,
                    values: [33.52, 0, 66.48],
                    ranking: "10th in China",
                },
                {
                    name: "吉林省",
                    enname: 'Jilin Province',
                    value: 160,
                    values: [19.21, 0, 80.79],
                    ranking: "12th in China",
                },
                {
                    name: "湖北省",
                    enname: 'Hubei province',
                    value: 174,
                    values: [13.76, 0, 86.24],
                    ranking: "12th in China",
                },
                {
                    name: "贵州省",
                    enname: 'Guizhou Province',
                    value: 178,
                    values: [11.82, 0.91, 87.27],
                    ranking: "13th in China",
                },
                {
                    name: "四川省",
                    enname: 'Sichuan',
                    value: 182,
                    values: [1.45, 0, 98.55],
                    ranking: "14th in China",
                },
                {
                    name: "天津市",
                    enname: 'Tianjin City',
                    value: 190,
                    values: [2.94, 0, 97.06],
                    ranking: "15th in China",
                },
                {
                    name: "重庆市",
                    enname: 'Chongqing City',
                    value: 197,
                    values: [9.24, 0, 90.76],
                    ranking: "16th in China",
                },
                {
                    name: "陕西省",
                    enname: 'Shaanxi province',
                    value: 206,
                    values: [14.89, 0, 85.11],
                    ranking: "17th in China",
                },
                {
                    name: "安徽省",
                    enname: 'Anhui Province',
                    value: 209,
                    values: [31.47, 2.8, 65.73],
                    ranking: "18th in China",
                },
                {
                    name: "新疆维吾尔自治区",
                    enname: 'the Xinjiang Uygur Autonomous Region',
                    value: 216,
                    values: [10, 2.5, 87.5],
                    ranking: "National 19th",
                },
                {
                    name: "北京市",
                    enname: 'Beijing',
                    value: 218,
                    values: [0, 0, 100],
                    ranking: "National 20th",
                },
                {
                    name: "江苏省",
                    enname: 'Jiangsu Province',
                    value: 220,
                    values: [1.28, 0.37, 98.35],
                    ranking: "21st in China",
                },
                {
                    name: "青海省",
                    enname: 'Qinghai Province',
                    value: 222,
                    values: [0, 0, 100],
                    ranking: "22nd in China",
                },
                {
                    name: "西藏自治区",
                    enname: 'Tibet Autonomous Region',
                    value: 262,
                    values: [0, 0, 100],
                    ranking: "23rd nationwide",
                },
                {
                    name: "上海市",
                    enname: 'Shanghai City',
                    value: 267,
                    values: [0, 0, 100],
                    ranking: "24th nationwide",
                },
                {
                    name: "河北省",
                    enname: 'Hebei Province',
                    value: 270,
                    values: [1.99, 2.79, 95.22],
                    ranking: "25th in China",
                },
                {
                    name: "河南省",
                    enname: 'Henan Province',
                    value: 271,
                    values: [4.45, 5.64, 89.91],
                    ranking: "26th in China",
                },
                {
                    name: "内蒙古自治区",
                    enname: 'Inner Mongolia Autonomous Region',
                    value: 279,
                    values: [7.69, 1.1, 91.21],
                    ranking: "27th in China",
                },
                {
                    name: "甘肃省",
                    enname: 'Gansu Province',
                    value: 284,
                    values: [0, 0, 100],
                    ranking: "28th in China",
                },
                {
                    name: "山西省",
                    enname: 'Shanxi Province',
                    value: 333,
                    values: [0, 2.12, 97.88],
                    ranking: "29th in China",
                },
                {
                    name: "宁夏回族自治区",
                    enname: 'the Ningxia Hui Autonomous Region',
                    value: 335,
                    values: [0, 0, 100],
                    ranking: "30th in China",
                },
                {
                    name: "山东省",
                    enname: 'Shandong Province',
                    value: 338,
                    values: [1.33, 3.15, 95.52],
                    ranking: "31st in China",
                },
            ]
        },
        //移动端
        //移动端导航栏
        mNavTop:[
            { name: 'Bench Ice Maker', path: '/mobile/iceMachine' },
            { name: 'Water Purifier Faucet', path: '/mobile/clearWater' },
            { name: 'Water Quality Map', path: '/mobile/waterMap' },
            { name: 'Research And Development', path: '/mobile/research/mResearch' },
            { name: 'Service Center', path: '/mobile/service/index' },
            { name: 'About Us', path: '/mobile/mAbout' },
        ],
        //首页
        mIndex:{
            //产品轮播
            mProBanners:[
                { name: 'Bench Ice Maker', imgSrc: 'metapure/metapure_web/Mobile/home/proBanners1.png', path: '/mobile/iceMachine', note: '10 minutes out of the ice, ice high-density no bubbles, strong ice force is not easy to melt, compact intelligent, good quality iceat any time.' },
                { name: 'Water Purifier Faucet', imgSrc: 'metapure/metapure_web/Mobile/home/proBanners2.png', path: '/mobile/clearWater', note: 'Aviation aluminum visualization, ceramiccomposite filter element, 4 doublefiltration, so that the water quality return to pure.' }
            ],
            //大家这样说
            talksTitle:'What People Say',
            talksList: [
                { userName: 'Plantain seed', userImg: 'metapure/metapure_web/Mobile/home/talks/userImg1.png', proImg: 'metapure/metapure_web/Mobile/home/talks/proImg1.png', talk: `The ice is very fast, the time can also beadjusted, you want what thickness of ice can be made, the ice is square ice, and the melting is slower than the ordinary ice~`, level: [4, 1] },
                { userName: 'Mandy jing', userImg: 'metapure/metapure_web/Mobile/home/talks/userImg3_1.png', proImg: 'metapure/metapure_web/Mobile/home/talks/proImg3_1.png', talk: `With it's own insulation function, it is not afraid to always wait for ice, want to do ice drinks, can take at any time, there is no need to wait, the value of this ice machine to buy!`, level: [5, 0] },
                { userName: 'crayon bear', userImg: 'metapure/metapure_web/Mobile/home/talks/userImg2.png', proImg: 'metapure/metapure_web/Mobile/home/talks/proImg2.png', talk: `For my family has been enough to use, 15 minutes of ice out, add a water can comeout several boards of ice, home to do drinks, do dishes are enough.`, level: [4, 1] },
                // {userName:'',userImg:'',proImg:'',talk:'',level:[]},
            ],
            //品牌介绍
            brand:{
                brandTitle1:'Return to the true, naturalpurity',
                brandTitle2:'Good life, health defined',
                p1:'Brand introduction. ',
                p2:'Metapure, pay attention to human drinking water health. Feel life with water, lead a new way of life, and bring more innovative health products.',
                p3:'Learn More'
            },
            // 关于我们
            Aboutus:{
                usTitle:'METAPURE: BEYOND WATER',
                usP1:'Our philosophy: Return to the true, pure and natural.',
                usP2:'A good life, defined by health.',
                usP3:'Metapure',
                usP4:' ',
                usP5:'is committed to providing consumers all over the world with pure and healthy water, living with water and leading a new healthy lifestyle.',
                usP6:'',
                usP7:'Learn More',
            },

        }
    },

}
export {
    home
}