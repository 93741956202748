import Vue from 'vue'
import App from './App'
import router from './router/index'
import store from './store/index';
import 'element-ui/lib/theme-chalk/index.css';
import { Progress } from 'element-ui';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import './router/permission.js'
import 'swiper/dist/css/swiper.css'
import 'lib-flexible/flexible.js'
import "@/font_family/font.css";


import ZH from '@/commom/enchi18n/zh.js'  //中文最终汇总暴露的信息
import EN from '@/commom/enchi18n/en.js'  //英文
import tool from "@/commom/tool";
Vue.use(tool)
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
const i18n=new VueI18n({
    locale:localStorage.getItem('languageSet')||'zh',   //从localStorage里获取用户中英文选择，没有则默认中文
    messages:{
        'zh': ZH,
        'en': EN
    }
})

// Vue.prototype.$message = Message
Vue.prototype.$eventBus = new Vue()
Vue.use(VueAwesomeSwiper)
Vue.prototype.splitImageHttp = (image => {
  return 'https://cdns.iamberry.com/' + image
})
Vue.use(Progress);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  created() {
    // 实例创建完成后被立即调用rem换算方法,解决内容闪现问题
    // if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
    //   //移动端使用
    //   setRem();
    //   window.addEventListener('resize', setRem);//浏览器窗口大小改变时调用rem换算方法
    // }
  }
  // 手机端

}).$mount('#app')
