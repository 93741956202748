import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/pc/Home'

Vue.use(Router)

export default new Router({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/home',
      name: 'Home',
      component: Home
    },
    {
      path: '/service',
      name: 'service',
      component:resolve => require(['../views/pc/service/serviceIndex'],resolve),
    },
    {
      path: '/service/hotQuestion',
      name: 'hotQuestion',
      component:resolve => require(['../views/pc/service/hotQuestion'],resolve),
    },
    {
      path: '/product/clearWater',
      name: 'clearWater',
      component:resolve => require(['../views/pc/clearWater'],resolve),
    },
    {
      path: '/about',
      name: 'about',
      component:resolve => require(['../views/pc/about/about'],resolve),
    },

    {
      path: '/service/porudctReplacement',
      name: 'porudctReplacement',
      component:resolve => require(['../views/pc/service/porudctReplacement'],resolve),
    },

    {
      path: '/research/sustainability',
      name: 'sustainability',
      component:resolve => require(['../views/pc/about/sustainability'],resolve),
    },
    {
      path: '/service/productMaintenance',
      name: 'productMaintenance',
      component:resolve => require(['../views/pc/service/productMaintenance'],resolve),
    },
    {
      path: '/service/productInstall',
      name: 'productInstall',
      component:resolve => require(['../views/pc/service/productInstall'],resolve),
    },
    {
      path: '/waterMap',
      name: 'waterMap',
      component:resolve => require(['../views/pc/waterMap'],resolve),
    },
    {
      path: '/product/iceMachine',
      name: 'iceMachine',
      component:resolve => require(['../views/pc/iceMachine'],resolve),
    },
    {
      path: '/research',
      name: 'research',
      component:resolve => require(['../views/pc/research'],resolve),
    },
    // 移動端頁面
    {
      path: '/mobile/home',
      name: 'mhome',
      component:resolve => require(['../views/mobile/home'],resolve),
    },
    {
      path: '/mobile/clearWater',
      name: 'mbclearWater',
      component:resolve => require(['../views/mobile/mbclearWater'],resolve),
    },
    {
      path: '/mobile/iceMachine',
      name: 'mbiceMachine',
      component:resolve => require(['../views/mobile/mbiceMachine'],resolve),
    },
    {
      path: '/mobile/service/index',
      name: 'serviceIndex',
      component:resolve => require(['../views/mobile/service/index'],resolve),
    },
    {
      path: '/mobile/research/mResearch',
      name: 'mResearch',
      component:resolve => require(['../views/mobile/research/mResearch'],resolve),
    },
    {
      path: '/mobile/research/mSustainability',
      name: 'mSustainability',
      component:resolve => require(['../views/mobile/research/mSustainability'],resolve),
    },    {
      path: '/mobile/service/productStand',
      name: 'productStand',
      component:resolve => require(['../views/mobile/service/productStand'],resolve),
    },
    {
      path: '/mobile/service/productInstall',
      name: 'mbproductInstall',
      component:resolve => require(['../views/mobile/service/productInstall'],resolve),
    },
    {
      path: '/mobile/service/filterReplace',
      name: 'filterReplace',
      component:resolve => require(['../views/mobile/service/filterReplace'],resolve),
    },
    {
      path: '/mobile/mAbout',
      name: 'mAbout',
      component:resolve => require(['../views/mobile/mAbout'],resolve),
    },
    {
      path: '/mobile/waterMap',
      name: 'mbwaterMap',
      component:resolve => require(['../views/mobile/waterMap'],resolve),
    },
  ]
})

// 防止连续点击多次路由报错
let routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err)
}