import router from '../router'
const mobileNmae = ['mhome','mbclearWater','mbiceMachine', 'serviceIndex', 'productStand', 'mbproductInstall','filterReplace','mResearch','mSustainability','mAbout','mbwaterMap']
router.beforeEach((to, from, next) => {
  if((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))){ // 窗口小于980展示移动端的UI页面  || navigator.maxTouchPoints>0
    if(mobileNmae.indexOf(to.name)!==-1){
        next()
    }else{
        next({path: '/mobile/home'})
    }
    return
  }
  next()
})
