import { home } from './info'
const en = {
    home: {
        menu: home.EN.menu
    },
    btMenu: {
        wxCode: home.EN.btMenu.wxCode,
        stCode: home.EN.btMenu.stCode,
        dyCode: home.EN.btMenu.dyCode,
        title1: home.EN.btMenu.title1,
        title2: home.EN.btMenu.title2,
        title3: home.EN.btMenu.title3,
        title4: home.EN.btMenu.title4,
        btabout: home.EN.btMenu.btabout,
        proCenter: home.EN.btMenu.proCenter,
        Service: home.EN.btMenu.Service,
        Research: home.EN.btMenu.Research,
        About: home.EN.btMenu.About,
    },
    homeIndex:{
        banners: home.EN.homeIndex.banners,
        talkList: home.EN.homeIndex.talkList,
    },
    Water: {
        waterinfo: home.EN.Water
    },
    icemake: {
        icemakeInfo: home.EN.icemake
    },
    watermap: {
        watermapInfo: home.EN.waterMap
    },
    service: {
        title: home.EN.service.title,
        lianxi: home.EN.service.lianxi,
        kefu: home.EN.service.kefu,
        Code: home.EN.service.Code,
        Center: home.EN.service.Center,
        productService: home.EN.service.productService,
        ct:home.EN.service.ct,
        proInstall: home.EN.service.proInstall,
        porudctReplacement: home.EN.service.porudctReplacement,
        //移动端
        hujiao: home.EN.service.hujiao,
        fuzhi: home.EN.service.fuzhi,
    },
    Aboutus: {
        title1: home.EN.Aboutus[0].title1,
        title2: home.EN.Aboutus[0].title2,
        oneSupport: home.EN.Aboutus[0].oneSupport,
        twoSupport: home.EN.Aboutus[0].twoSupport,
        company: home.EN.Aboutus[0].company,
        companyAddress: home.EN.Aboutus[0].companyAddress,
        title3: home.EN.Aboutus[1].title3,
        qualityBanner: home.EN.Aboutus[1].qualityBanner,
        title4: home.EN.Aboutus[2].title4,
        html: home.EN.Aboutus[2].html,
        menuList: home.EN.Aboutus[3].menuList,
        // 移动端
        Supports: home.EN.Aboutus[0].Supports,
        qualityBanner1: home.EN.Aboutus[1].qualityBanner1,
        qualityBanner2: home.EN.Aboutus[1].qualityBanner2,
        buttonText1: home.EN.Aboutus[1].buttonText1,
        buttonText2: home.EN.Aboutus[1].buttonText2,
        mHtml: home.EN.Aboutus[2].mHtml,

    },
    research: {
        menuList: home.EN.research[0].menuList,
        title1: home.EN.research[1].title1,
        yanjiuBanner: home.EN.research[1].yanjiuBanner,
        title2: home.EN.research[2].title2,
        sustainabilityList: home.EN.research[2].sustainabilityList,
        check: home.EN.research[2].check, 
        title3: home.EN.research[3].title3,
        productivityList: home.EN.research[3].productivityList,
    },
    sustainability:{
        title1: home.EN.sustainability[0].title1,
        qualityBanner: home.EN.sustainability[0].qualityBanner,
        title2: home.EN.sustainability[1].title2,
        actionList: home.EN.sustainability[1].actionList,
        //移动端
        protectionBanner1: home.EN.sustainability[0].protectionBanner1,
        protectionBanner2: home.EN.sustainability[0].protectionBanner2,
        buttonText1:home.EN.sustainability[0].buttonText1,
        buttonText2:home.EN.sustainability[0].buttonText2,
    },
    //移动端
    mNavTop:{
        menuList: home.EN.mNavTop,
    },
    mIndex:{
        proBanner: home.EN.mIndex.mProBanners,
        talksTitle: home.EN.mIndex.talksTitle,
        talksList: home.EN.mIndex.talksList,
        brand: home.EN.mIndex.brand,
        Aboutus: home.EN.mIndex.Aboutus,

    }
}
export default en