import { home } from './info.js'
const zh = {
    home: {
        menu: home.ZH.menu
    },
    btMenu: {
        wxCode: home.ZH.btMenu.wxCode,
        stCode: home.ZH.btMenu.stCode,
        dyCode: home.ZH.btMenu.dyCode,
        title1: home.ZH.btMenu.title1,
        title2: home.ZH.btMenu.title2,
        title3: home.ZH.btMenu.title3,
        title4: home.ZH.btMenu.title4,
        btabout: home.ZH.btMenu.btabout,
        proCenter: home.ZH.btMenu.proCenter,
        Service: home.ZH.btMenu.Service,
        Research: home.ZH.btMenu.Research,
        About: home.ZH.btMenu.About,
    },
    homeIndex:{
        banners:home.ZH.homeIndex.banners,
        talkList: home.ZH.homeIndex.talkList,
    },
    Water: {
        waterinfo: home.ZH.Water
    },
    icemake: {
        icemakeInfo: home.ZH.icemake
    },
    watermap: {
        watermapInfo: home.ZH.waterMap
    },
    service: {
        title: home.ZH.service.title,
        lianxi: home.ZH.service.lianxi,
        kefu: home.ZH.service.kefu,
        Code: home.ZH.service.Code,
        Center: home.ZH.service.Center,
        productService: home.ZH.service.productService,
        ct:home.ZH.service.ct,
        proInstall: home.ZH.service.proInstall,
        porudctReplacement: home.ZH.service.porudctReplacement,
        //移动端
        hujiao: home.ZH.service.hujiao,
        fuzhi: home.ZH.service.fuzhi,
    },
    Aboutus: {
        title1: home.ZH.Aboutus[0].title1,
        title2: home.ZH.Aboutus[0].title2,
        oneSupport: home.ZH.Aboutus[0].oneSupport,
        twoSupport: home.ZH.Aboutus[0].twoSupport,
        company: home.ZH.Aboutus[0].company,
        companyAddress: home.ZH.Aboutus[0].companyAddress,
        title3: home.ZH.Aboutus[1].title3,
        qualityBanner: home.ZH.Aboutus[1].qualityBanner,
        title4: home.ZH.Aboutus[2].title4,
        html: home.ZH.Aboutus[2].html,
        menuList: home.ZH.Aboutus[3].menuList,
        // 移动端
        Supports: home.ZH.Aboutus[0].Supports,
        qualityBanner1: home.ZH.Aboutus[1].qualityBanner1,
        qualityBanner2: home.ZH.Aboutus[1].qualityBanner2,
        buttonText1: home.ZH.Aboutus[1].buttonText1,
        buttonText2: home.ZH.Aboutus[1].buttonText2,
        mHtml: home.ZH.Aboutus[2].mHtml,
    },
    research: {
        menuList: home.ZH.research[0].menuList,
        title1: home.ZH.research[1].title1,
        yanjiuBanner: home.ZH.research[1].yanjiuBanner,
        title2: home.ZH.research[2].title2,
        sustainabilityList: home.ZH.research[2].sustainabilityList,
        check: home.ZH.research[2].check, 
        title3: home.ZH.research[3].title3,
        productivityList: home.ZH.research[3].productivityList,
    },
    sustainability:{
        title1: home.ZH.sustainability[0].title1,
        qualityBanner: home.ZH.sustainability[0].qualityBanner,
        title2: home.ZH.sustainability[1].title2,
        actionList: home.ZH.sustainability[1].actionList,
        //移动端
        protectionBanner1: home.ZH.sustainability[0].protectionBanner1,
        protectionBanner2: home.ZH.sustainability[0].protectionBanner2,
        buttonText1:home.ZH.sustainability[0].buttonText1,
        buttonText2:home.ZH.sustainability[0].buttonText2,
    },
    //移动端
    mNavTop:{
        menuList: home.ZH.mNavTop,
    },
    mIndex:{
        proBanner: home.ZH.mIndex.mProBanners,
        talksTitle: home.ZH.mIndex.talksTitle,
        talksList: home.ZH.mIndex.talksList,
        brand: home.ZH.mIndex.brand,
        Aboutus: home.ZH.mIndex.Aboutus,
    }
}
export default zh